.accessories-list {
    .product-caytegory-pagination {
        margin: unset;
        margin-left: 10px;
    }

    .accessories-list-items {
        width: calc(100% - 430px);
    }

    .post-other {
        margin-left: 10px;
        padding: 0.75rem 1rem;
        margin-bottom: 25px;

        h4 {
            border-bottom: 1px solid #ccc;
            padding-bottom: 5px;
        }
    }



    .accessories .para {
        border-bottom: dotted 1px #545454;
        padding-bottom: 10px;
        margin-bottom: 25px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }


    @media (max-width: 991px) {
        .accessories .left.col-md-4 img {
            width: 100% !important;
            height: 380px !important;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 1199px) {
        .accessories .left.col-md-4 img {
            width: 100% !important;
        }
    }
}


.product-categories-page.accessories-list {
    .card {
        width: 100%;
    }
}

.accessories-card {

    .post-card--layout--list {
        background-color: #fff;
        box-shadow: 0px 2px 10px #0000001a;
        display: flex;
    }

    @media (min-width: 1200px) and (max-width: 1399.98px) {
        .post-card__date:before {
            display: block;
            width: 36px;
            margin-top: 14px;
            margin-bottom: 10px;
        }
    }

    @media (min-width: 768px) {
        .post-card__date:before {
            content: "";
            height: 1px;
            background: currentColor;
            opacity: 0.6;
        }
    }

    .posts-list__item {
        margin-left: 10px;
        margin-bottom: 25px;
    }

    .post-card__title a {
        color: inherit;
    }

    @media (max-width: 768px) {
        .post-card--layout--list {
            display: block;
        }

        .post-card__image {
            height: 250px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399.98px) {
        .post-card__excerpt {
            max-height: 78px;
        }
    }

    @media (min-width: 768px) {
        .post-card__excerpt {
            overflow: hidden;
            margin-bottom: auto;
        }
    }

    .post-card__image {
        max-width: 100%;
        height: auto;
    }

    .post-card__image,
    .post-card__image img {
        width: 250px;
        height: 200px;
        padding: 10px;

    }

    @media (min-width: 768px) {
        .post-card__image {
            display: flex;
            flex-shrink: 0;
        }

        .post-card__image img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }

    .post-card__content {
        padding: 20px 26px 22px;
    }

    .post-card__date,
    .post-card__date a {
        font-size: 14px;
        color: #999;
        margin-bottom: 10px;
    }
}