@media (max-width: 1199.98px) {
  .post-header {
    padding-top: 0;
  }
}
.post-header--has-image {
  background: #262626;
  margin-bottom: -150px;
  padding-bottom: 150px;
}

.post-view {
  min-height: 500px;
  position: relative;
}
.post-header {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}

.post-header__body {
  color: #fff;
  padding: 60px 30px;
}
.post-header__body {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 52px 30px;
}

.post-header--has-image .post-header__meta {
  color: inherit;
}

.post-header__meta {
  font-size: 14px;
  margin-top: 24px;
  color: #999;
}

.post-header__meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.post-header__meta-item + .post-header__meta-item:before {
  left: -14px;
}
.post-header__meta-item + .post-header__meta-item:before {
  display: block;
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
  top: 9px;
}

.post-header__meta-item + .post-header__meta-item {
  margin-left: 24px;
}
.post-header__meta-item + .post-header__meta-item {
  position: relative;
}

.post-header__meta-link {
  color: inherit;
  transition: color 0.1s;
}

.post-header__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50%;
  z-index: -1;
  opacity: 0.22;
}

.post-header--has-image .post-header__body {
  color: #fff;
  padding: 60px 30px;
}

.post-header__body {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 52px 30px;
}

@media (max-width: 991.98px) {
  .post-view__body {
    flex-direction: column;
  }
}
.post-view__body {
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
}

.post-view__item-post {
  -moz-box-flex: 1;
  flex-grow: 1;
  max-width: 760px;
}

.post-view__card {
  background-color: #fff;
  box-shadow: 0px 2px 10px #0000001a;
  position: relative;
}

.post__body {
  padding: 48px;
  font-size: 16px;

  img {
    width: 100%;
  }

  b {
    font-weight: 400;
  }
}

.typography blockquote {
  margin: 2.25rem 0;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 2.25em 2.25em 2em;
}

.typography blockquote cite {
  margin-top: -0.5em;
  color: #6c757d;
  display: block;
  font-size: 15px;
  font-style: normal;
}

.typography figure {
  margin: 1.875rem 0 2.25rem;
}

.typography img {
  max-width: 100%;
  border-radius: 2px;
}

.typography figcaption {
  font-size: 14px;
  text-align: center;
  color: #6c757d;
  padding-top: 0.875rem;
}

.typography hr {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  border: none;
  background: #d9d9d9;
  margin: 2.5em auto;
}

.typography hr:before {
  right: -30px;
}

.typography hr:after {
  left: -30px;
}
.typography hr:after,
.typography hr:before {
  position: absolute;
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}

.typography h4 {
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .post__footer {
    padding: 0 32px 24px;
  }
}
@media (max-width: 767.98px) {
  .post__footer,
  .post__pagination {
    flex-direction: column;
    align-items: center;
  }
}
.post__footer {
  display: flex;
  justify-content: space-between;
  padding: 0 48px 48px;
  margin-top: 24px;
}

@media (max-width: 767.98px) {
  .post__tags .tags__list {
    -moz-box-pack: center;
    justify-content: center;
  }
}
.tags__list {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  margin: -2px;
}

.tags--sm .tags__list a {
  padding: 2px 8px;
}

.tags__list a {
  display: block;
  margin: 2px;
  background: #f2f2f2;
  padding: 4px 11px;
  color: inherit;
  border-radius: 1.5px;
  transition: background 0.12s;
  font-size: 13px;
}

.post__share-links {
  margin-top: 20px;
  padding-bottom: 0;
}

.share-links__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.share-links__item--type--like a {
  background: #4267b2;
}
.share-links__item a,
.share-links__item a:hover {
  color: inherit;
  text-decoration: none;
}
.share-links__item a {
  border-radius: 1.5px;
  display: block;
  padding: 2px 7px 1px;
  color: #fff;
}

.share-links__item--type--tweet a {
  background: #1b95e0;
  color: #fff;
}

.share-links__item--type--pin a {
  background: #e63939;
  color: #fff;
}

.share-links__item + .share-links__item {
  margin-left: 4px;
}

.share-links__item--type--counter a {
  background: #fff;
  color: #737373;
  border: 1px solid #ccc;
}

@media (max-width: 767.98px) {
  .post__author {
    padding: 32px 0;
    flex-direction: column;
  }
}

.post__author {
  display: flex;
  align-items: center;
  border-top: 1px solid #ebebeb;
}

.post__author-avatar img {
  border-radius: 50%;
  max-height: 100px;
}

.post__author-avatar {
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 2px;
  margin: 10px 0;
}

.post__footer {
  padding: 0 32px 24px;
}

.post__author-info {
  text-align: center;
  margin-top: 16px;
}

.post__author-name {
  font-weight: 500;
}

.post__author-about {
  font-size: 15px;
  margin-top: 6px;
}

.post-view__card {
  margin-top: 24px;
}

.post-view__card {
  background-color: #fff;
  box-shadow: 0px 2px 10px #0000001a;
  position: relative;
}

.post-navigation__body {
  flex-direction: row;
}

.post-navigation__body,
.post-navigation__item {
  display: flex;
  justify-content: end;
}

.post-navigation__item--prev {
  text-align: left;
  justify-content: start;
}

@media (max-width: 767.98px) {
  .post-navigation__item {
    width: 100% !important;
  }

  .post-navigation__body {
    flex-direction: column;
  }
}
.post-navigation__item {
  padding: 20px;
  align-items: center;
  width: 50%;
  color: inherit;
  transition: background 0.12s;
  cursor: pointer;
}

.post-navigation__item--prev .post-navigation__item-image {
  margin-right: 18px;
}

.post-navigation__item-image {
  overflow: hidden;
  border-radius: 2px;
  flex-shrink: 0;
}

.post-navigation__item-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.post-navigation__item--prev .post-navigation__direction {
  justify-content: flex-start;
}

.post-navigation__direction {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  color: #999;
}

.post-navigation__item-title {
  font-size: 15px;
  line-height: 22px;
  margin-top: 3px;
  margin-left: 5px;
}

.post-navigation__direction-arrow svg {
  fill: #999;
  margin: 0 5px 0 5px;
}

.post-navigation__direction-title {
  margin-left: 5px;
}

.post-navigation__item-hover:hover {
  background: #f2f2f2;
  color: inherit;
  text-decoration: none;
}

.post-navigation__item--next .post-navigation__direction {
  justify-content: flex-end;
}

.post-navigation__item--next {
  border-top: 1px solid #ebebeb;
  .post-navigation__item-title {
    text-align: right;
  }
}
.post-navigation__item--next .post-navigation__item-image {
  margin-left: 18px;
}

.post-view__card-title {
  padding: 28px 0 8px;
  margin: 0 32px 0;
  font-size: 28px;
  font-weight: 700;
}

.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.post-view__card-body {
  padding: 0 32px 32px;
}

.comment__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.comment__avatar {
  overflow: hidden;
  border-radius: 50%;
  margin-right: 14px;
  width: 38px;
}

.comment__avatar img {
  width: 38px;
  height: 38px;
  object-fit: cover;
}

.comment__meta {
  margin-right: 12px;
  flex-grow: 1;
}

.comment__author {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  margin-top: 3px;
}

.comment__date {
  font-size: 13px;
  color: #999;
  margin-top: -1px;
}

.comment__content {
  width: 100%;
  background: #f5f5f5;
  padding: 12px 17px;
  border-radius: 5px;
  margin-top: 12px;
  font-size: 15px;
  position: relative;
}

.comment__content:before {
  position: absolute;
  content: "";
  display: block;
  left: 14px;
  top: -10px;
  border: 5px solid transparent;
  border-bottom-color: #f5f5f5;
  pointer-events: none;
}

.comments-list__children {
  border-left: 1px solid #ebebeb;
  padding-left: 31px;
  margin-left: 18px;
  margin-top: 14px;
  padding-top: 14px;
}

.comments-list__item + .comments-list__item {
  margin-top: 24px;
}

.post-view__card-sub-title {
  margin: 0 30px 25px;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebebeb;
}

@media (max-width: 767.98px) {
  .post__body a {
    word-wrap: break-word;
  }
}
@media (max-width: 450px) {
  .post__body img {
    height: 300px;
    width: 250px;
  }
}
@media (max-width: 320px) {
  .post__body img {
    height: 200px;
    width: 150px;
  }
}
@media (max-width: 375px) {
  .post__body img {
    height: 220px;
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
