@import "./variables.scss";

* {
  font-family: "Roboto", "sans-serif";
}

html {
  scroll-behavior: smooth;
}

//logo css starts
.logo-image {
  img {
    height: 70px;
  }

  @media (max-width: 845px) {
    img {
      height: 45px;

    }
  }
}

//logo css ends

//breadcrumb css starts
.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  color: #2daed7;
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: .25rem;
  padding-top: 16px;
  padding-bottom: 40px;

  .active {
    color: #222;

    a {
      pointer-events: none;
      cursor: default;
    }

  }

  .breadcrumb_name {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
    height: 20px;
  }


  .breadcrumb__list {
    display: block;
    list-style: none;
    padding: 0;
    margin: -4px 0 0;
    width: 100%;
  }

  .breadcrumb__item-link,
  .breadcrumb__item-link:hover {
    color: inherit;
  }

  .breadcrumb__item--first .breadcrumb__item-link {
    padding-left: 7px;
  }

  .breadcrumb__item {
    margin-right: -7.12435px;
    float: left;
  }

  .breadcrumb__item {
    margin-top: 4px;
  }

  .breadcrumb__item-link:before {
    left: 6.062175px;
    right: 6.062175px;
    -webkit-transform: skewX(-30deg);
    -moz-transform: skewX(-30deg);
    transform: skewX(-30deg);
  }

  .breadcrumb__item-link {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;
    height: 20px;
    padding: 0;
    pointer-events: none;

    &,
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:before,
    &:after {
      pointer-events: auto;
      position: absolute;
      background-color: #ebebeb;
      top: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 3px;
      transition: background .1s;
    }

    &:before {
      content: '';


    }
  }

  .breadcrumb__item--first .breadcrumb__item-link:after {
    content: "";
    left: 0;
    right: 12.12435px;
  }

  .breadcrumb__item-link {

    display: flex;
    -moz-box-align: center;
    align-items: center;
    position: relative;
    z-index: 0;
    height: 21px;
    padding: 0 15.12435px;
    pointer-events: none;
  }

}

//breadcrumb css ends


//login css starts
.login-page {
  .card-title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 2rem;
    margin-top: -4px;
  }

  a {
    color: #32c2ef;
  }

  .text-muted {
    color: #6c757d !important;
  }

  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
}

@media screen and (max-width: 575px) {

  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

//login css ends

//contact us css starts
.contact-us {
  min-height: 300px;

  .block-map {
    display: block;
    position: relative;
    height: 420px;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      z-index: 1;
      pointer-events: none;
    }

  }

  .body-row>div {
    padding: 1.25rem;
  }

  .contact-us-row {
    background-color: $primary-color;
    color: #fff;
  }

  .card-body {
    padding: unset;
  }

  .card-title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 2rem;
    margin-top: -4px;
  }


  .block-header__title {
    font-size: 2.25rem;
    font-weight: 700;
    margin: 0;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .block-header__breadcrumb+.block-header__title {
    margin-top: -77px;
  }
}

//contact us css ends

//faq css starts
.faq {
  .faq__header {
    padding: 44px 0 52px;
    text-align: center;
  }

  .faq__header-title {
    font-weight: 700;
    margin: 0;
  }

  .faq__section {
    background-color: #fff;
    box-shadow: 0px 2px 10px #0000001a;
    padding: 48px;

    ul {
      padding-left: 20px;
    }

    .typography h4 {
      color: #32c2ef;
      font-weight: 500;
    }
  }

  .faq__section-title {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 1rem;
    margin-top: -4px;
    color: #32c2ef;
  }

  .faq__question-title {
    margin-bottom: 14px;
  }

  .faq__section+.faq__section {
    margin-top: 30px;
  }

  .faq__footer {
    margin-top: 56px;
    text-align: center;
  }

  .faq__footer-title {
    font-size: 28px;
    margin-bottom: 8px;
  }

  .faq__footer-subtitle {
    margin-bottom: 36px;
    color: #6c757d;
  }

  @media (max-width: 400px) {

    .faq__header {
      padding: 20px 0;
    }

  }

}

//faq css ends

//footer css starts
.site__footer {
  flex-shrink: 0;
  background-color: #333;
  color: #9e9e9e;
  position: relative;

  @media (max-width: 767.98px) {
    .site-footer__widgets {
      padding: 40px 0 36px;
    }
  }

  .site-footer__widgets {
    padding: 56px 0 50px;
  }

  .footer-links__title {
    font-size: 20px;
    color: #fff;
    margin-bottom: 22px;
    font-weight: 500;
  }

  .footer-links__contact,
  .contact-info {
    list-style-type: none;
    padding-left: unset;

    li {
      padding: 0px 0px 10px 0px;
    }

    .fa-map-marker {
      margin-right: 10px;
    }
  }

  .footer-links__grid {
    display: grid;
    grid-template-columns: 42px auto;
    -moz-box-align: center;
    align-items: center;
  }

  .footer_link_contact_details .footer-links__item {
    padding: 15px 0;
    line-height: 25px;
  }

  .footer-links__grid .footer-links__contact {
    color: $primary-color;
  }

  .footer-links__list {
    font-size: 15px;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }

  .footer_link_our_menu .footer-links__item {
    padding: 7px 0;
  }

  .footer-links__list a {
    color: inherit;
  }

  .footer-links__list a:hover {
    color: $white;
    text-decoration: none;
  }

  @media (max-width: 1199.98px) {
    .footer-contacts__title {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }

  .footer-contacts__title {
    font-size: 24px;
    color: #fff;
    margin-bottom: 26px;
    font-weight: 500;
  }

  .footer-contacts__text {
    line-height: 22px;
    list-style: none;
    margin: 0;
    font-size: 15px;

    a {
      color: $primary-color;
    }
  }

  @media (max-width: 1199.98px) {
    .footer-contacts {
      text-align: center;
      margin-bottom: 42px;
    }
  }

  .footer-contacts {
    display: block;
  }

  .footer-newsletter__title {
    font-size: 20px;
    color: #fff;
    margin-bottom: 26px;
    font-weight: 500;
  }

  .footer-newsletter__form {
    display: flex;
    max-width: 380px;

    button:hover {
      background-color: $primary-color;
      color: #2b2b2b;
    }
  }

  .footer-newsletter__form-button {
    margin-left: 8px;
  }

  .footer-newsletter__form-button {
    background-color: $primary-color;
  }

  .footer-newsletter__text {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 14px;
  }

  .footer-newsletter__text--social {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .footer-newsletter__text {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 14px;
  }

  .site-footer__bottom {
    background-color: #2b2b2b;
    font-size: 14px;
    color: #9e9e9e;
    font-weight: 400;
  }

  .site-footer__bottom-row {
    display: flex;
    height: 72px;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
  }

  .site-footer__payments {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .footer_link_our_menu ul {
    list-style: disc;
    margin-left: 15px;
  }

  .social-links__item--facebook a {
    background: #3c5a99;
    color: #fff;
  }

  .social-links__item--youtube a {
    background: #e52e2e;
  }

  .fa-facebook-f,
  .fa-youtube {
    color: #fff;
  }

  .social-links__item {
    margin: 4px;
  }

  .social-links__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: -4px;
  }

  .social-links__item a {
    font-size: 16px;

    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    color: inherit;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
  }

  .social-links__item a:hover {
    opacity: 0.8;
    text-decoration: none;
  }

}

//footer css ends

//account layout css starts
.account-layout {
  .account-nav {
    background-color: #fff;
    box-shadow: 0px 2px 10px #0000001a;
    height: 100%;
  }

  .account-nav__title {
    padding: 1.375rem 1.5rem;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    color: #32c2ef;
  }

  .account-nav__item--active {
    font-weight: 700;
  }

  .account-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 1.375rem;
  }

  .account-nav__item a,
  .account-nav__item button {
    cursor: pointer;
    display: block;
    color: #6c757d;
    padding: 7px 1.5rem;
    border: none;
    width: 100%;
    background: transparent;
  }

  .account-nav__divider {
    height: 1px;
    background: #ebebeb;
    margin: 10px 0;
  }

  .account-nav__item button {
    text-align: left;
  }

  .account-nav__item:hover a,
  .account-nav__item:hover button {
    background: #f2f2f2;
  }

  .rewards {
    .card-table {
      min-height: 300px;
      position: relative;
    }
  }


  .points-heading {
    font-size: 1.25rem;
  }
}

//account layout css ends

//about us css starts
.about {
  min-height: 350px;

  @media (min-width: 1200px) {
    .about__body {
      grid-template-rows: 124px auto auto;
    }
  }

  @media (min-width: 1200px) {
    .about__body {
      grid-template-columns: 1fr 380px 730px 1fr;
      grid-template-rows: 100px auto auto;
    }
  }

  .about__body {
    display: grid;
    z-index: 0;
  }

  .about__card {
    background-color: #fff;
    box-shadow: 0px 2px 10px #0000001a;
    border-radius: 2px;
    padding: 32px 36px;

    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    min-height: 530px;
  }

  .about__card-title {
    font-size: 40px;
    font-weight: 700;
    padding: 16px 0 40px;
    text-align: center;
    margin-bottom: 0;
  }

  .about__card-text {
    text-align: center;
    line-height: 1.75;
  }

  .about {
    min-height: 350px;
  }

  .about__card-author {
    color: #6c757d;
    font-size: 14px;
    position: relative;
    align-self: center;
    margin-top: 16px;
    padding-left: 28px;
  }

  .about__image-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about__image {
    overflow: hidden;
    z-index: -1;
    grid-column: 1/5;
    grid-row: 1/3;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    height: 700px;
  }

  @media (min-width: 1200px) {
    .about__image {
      grid-column: 1/5;
      grid-row: 1/3;
    }
  }

  .about__body {
    grid-template-columns: 1fr 380px (1110px - 380px) 1fr;
    grid-template-rows: 100px auto auto;
  }

  .about__image {
    grid-column: 1 / 5;
    grid-row: 1 / 3;
  }

  .about__card {
    grid-column: 2;
    grid-row: 2 / 4;
  }

  .about__indicators-body {
    display: flex;
    flex-wrap: wrap;
  }

  .about__indicators {
    grid-column: 3;
    grid-row: 3;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 52px;
  }

  .about__indicators-item {
    margin: 12px;
    width: -webkit-calc(100%/3 - 24px);
    width: -moz-calc(100%/3 - 24px);
    width: calc(100% / 3 - 24px);
  }

  .about__indicators-item-value {
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    color: #32c2ef;
  }

  .about__indicators-item-title {
    color: #32c2ef;
    font-size: 15px;
    line-height: 10px;
  }

  .about__card-author:before {
    display: block;
    height: 1px;
    width: 20px;
    background: currentColor;
    content: "";
    opacity: 0.8;
    position: absolute;
    top: 10px;
    left: -3px;
  }

  .about__card-signature {
    padding: 40px 0 12px;
    margin-top: auto;
    align-self: center;
  }

  .about__image-bg:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear,
        left top,
        left bottom,
        from(rgba(51, 51, 51, 0.7)),
        to(rgba(51, 51, 51, 0.4)));
    background: -webkit-linear-gradient(top,
        rgba(51, 51, 51, 0.7),
        rgba(51, 51, 51, 0.4));
    background: -moz-linear-gradient(top,
        rgba(51, 51, 51, 0.7),
        rgba(51, 51, 51, 0.4));
    background: linear-gradient(180deg,
        rgba(51, 51, 51, 0.7),
        rgba(51, 51, 51, 0.4));
  }

  @media (max-width: 824px) {
    .about__indicators-body {
      display: block;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  @media (max-width: 450px) {
    .about__indicators-item-value {
      font-size: 22px;
    }

    .about .block-reviews__subtitle {
      margin-top: 20px !important;
    }

  }


  @media (max-width: 650px) {

    .about__image {
      height: 755px;
    }

    .about__indicators-body {
      left: 60px;
      position: absolute;
      display: flex;
      margin-top: 75px;
    }

    .about__card {
      width: calc(100vw - 50px);
      margin: 0 10px 0 25px;
      grid-column: unset;
    }

    .about__body {
      grid-template-rows: 40px auto auto 200px !important;
    }

    .about .block-space--layout--before-footer {
      height: 40px;
      display: none;
    }

    .about .block-reviews__subtitle {
      font-size: 20px;
      margin-top: unset;
    }

  }

  @media (max-width: 320px) {
    .about__indicators-item-title {
      margin-top: 5px;
    }

    .about__indicators-item-value {
      margin-top: 35px;
    }
  }


  .about__indicators-body {
    margin-bottom: 100px;
  }

  @media (min-width: 984px) and (max-width: 1200px) {
    .about .block-reviews__subtitle {

      margin-top: 80px !important;
    }

  }


  @media (max-width: 1200px) {

    .about__body {
      grid-template-columns: 75px 1fr 1fr;
      grid-template-rows: 50px auto auto;
    }



  }

  @media (max-width: 588px) {
    .about__body {
      grid-template-rows: 40px auto auto 157px !important;
    }

  }

  @media (max-width: 425px) {
    .about__image {
      height: 700px;
    }

  }

  @media (max-width: 450px) {
    .about__image {
      height: 735px;
    }

  }

  @media (max-width: 375px) {
    .about .block-reviews__subtitle {
      margin-top: 5px !important;
    }

    .about__image {
      height: 760px;
    }

  }

  @media (max-width: 350px) {
    .about__body {
      grid-template-rows: 40px auto auto 170px !important;
    }

    .about__indicators-body {
      left: 35px;
      margin-top: 60px;
    }


  }

  @media (max-width: 340px) {

    .about__indicators-item {
      margin: 12px;
      width: calc(100% / 2 - 15px);
    }

    .about__image {
      height: 820px;
    }
  }

  @media (max-width: 321px) {

    .about__indicators-body {
      margin-top: 25px;
    }

    .about__image {
      height: 840px;
    }
  }

  @media (max-width: 900px) {
    .about__body {
      grid-template-columns: 50px 450px 1fr;
      grid-template-rows: 50px auto auto;
    }
  }

  @media (max-width: 450px) {

    .about__card-title {
      padding: unset;
    }

    .about__body {
      grid-template-rows: 40px auto auto 128px !important;
    }

  }

}

//about us css ends

//block caraousel css starrts
.block_product_caraousel {
  min-height: 400px;

  .block-products-carousel__carousel-loader:before {
    position: absolute;
    display: block;
    content: "";
    left: -10px;
    top: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: #fafafa;
    opacity: 0.9;
  }

  .block-products-carousel__carousel--loading:not(.block-products-carousel__carousel--has-items) {
    min-height: 120px;
  }

  .block-products-carousel__carousel {
    position: relative;
    margin: 20px 0;
  }

  .block-products-carousel__carousel-loader:after {
    left: calc(50% - 50px);
    top: -moz-calc(50% - 50px);
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgba(0, 0, 0, 0.5);
    animation-name: loader-animation;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    position: absolute;
    display: block;
    content: "";
  }

  .product-card__badges>*+* {
    margin-top: 3px;
  }

  .product-card__action:focus {
    outline: none;
  }

  @media (max-width: 320px) {
    .block-products-carousel__carousel {
      width: 320px;
    }
  }
}

//block caraousel css ends

//section header css starts
.section-header {
  .section-header__body {
    margin-bottom: 35px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .section-header__title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    position: relative;
    top: 2px;
  }

  .section-header__spring {
    -moz-box-flex: 1;
    flex-grow: 1;
  }

  .section-header__groups {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .section-header__arrows {
    display: flex;
  }

  .section-header__groups-button--active,
  .section-header__groups-button--active:active,
  .section-header__groups-button--active:hover {
    color: #fff !important;
    cursor: default;
  }

  .section-header__groups-button {
    font-size: 14px;
    height: 23px;
    border: none;
    padding: 0 18.37131px;
    line-height: 23px;
    display: block;
    position: relative;
    z-index: 0;
    pointer-events: none;
    -webkit-transition: color 0.1s;
    -moz-transition: color 0.1s;
    transition: color 0.1s;
    background: transparent;
    color: #6c757d;
  }

  .section-header__groups-button:after {
    left: 0;
    transform: skewX(-20deg);
    transform-origin: left bottom;
    position: absolute;
    display: block;
    content: "";
    width: calc(100% - 8.37131px);
    height: 100%;
    top: 0;
    background: transparent;
    z-index: -1;
    pointer-events: auto;
    transition: background 0.1s;
  }

  .section-header__groups-button--active:active:after,
  .section-header__groups-button--active:after,
  .section-header__groups-button--active:hover:after {
    background: #333;
  }

  .section-header__groups-button:hover {
    color: #262626;
  }

  .section-header__groups-button:active:after {
    background: #e0e0e0;
  }

  .section-header__groups-button:focus {
    outline: none;
  }

  .section-header__groups+.section-header__arrows {
    margin-left: -5.37131px;
  }

  .section-header__arrow--next {
    margin-left: -5.37131px;
  }

  .arrow__button:before {
    left: 4.185655px;
  }

  .arrow__button:before {
    width: -webkit-calc(100% - 8.37131px);
    width: -moz-calc(100% - 8.37131px);
    width: calc(100% - 8.37131px);
    background: #32c2ef;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }

  .arrow__button {
    height: 23px;
    padding: 0 14.37131px;
  }

}

//section header css ends

.product-loader-div {
  min-height: 300px;
  position: relative;
}


//page not found css starts
.page-not-found {

  .not-found {
    text-align: center;
    padding: 36px 0;
  }

  .not-found__404 {
    font-size: 80px;
    font-weight: 700;
    color: #ebebeb;
    padding: 20px 0 4px;
  }

  .not-found__title {
    margin-bottom: 20px;
    font-weight: 700;
  }

  .not-found__content {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
  }

  .not-found__search {

    display: flex;
    margin-bottom: 30px;
  }

  .not-found__text {
    margin-bottom: 20px;
  }

  .not-found__search-input {
    margin-right: 10px;
  }

  .not-found__search-button {
    background-color: $primary-color;
    border-color: $primary-color;

  }

  @media (max-width: 767.98px) {
    .not-found__404 {
      font-size: 50px;
      line-height: 54px;
      padding: 40px 0 32px;
    }
  }



  @media (max-width: 991.98px) {
    .not-found__404 {
      font-size: 60px;
    }
  }


  @media (max-width: 767.98px) {
    .not-found__title {
      margin-bottom: 20px;
    }
  }
}

//page not found css ends

//privacy and terms css starts
.privacy-and-terms {
  @media (max-width: 1199.98px) {
    .privacy-policy__header {
      padding: 48px 0;
    }
  }

  .privacy-policy__header {
    padding: 44px 0 52px;
    text-align: center;
  }

  .privacy-policy__header-title {
    font-weight: 700;
    margin: 0;
  }

  .typography> :first-child {
    margin-top: 0;
  }

  .typography p {
    margin-bottom: 40px !important;
  }

  .privacy-policy__section {
    padding: 40px;
  }


  .privacy-policy a {
    color: inherit;
    text-decoration: none;
  }


  .privacy-policy__question-title {
    margin-bottom: 14px;
    font-size: 1.25rem;
  }

}

//privacy and terms css ends
//block feature css starts
.block-features {
  background-color: #fff;
  padding: 30px 0;

  .block-features__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
    justify-content: center;
  }

  .block-features__item {
    display: flex;
    width: calc((100% - 3px) / 4);
    padding: 1.5rem;
    -moz-box-pack: center;
    justify-content: flex-start;
  }

  .block-features__item-icon {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    fill: #32c2ef;
    margin-right: 1.375rem;
  }

  .block-features__item-title {
    font-size: 16px;
    line-height: 1.125;
    padding: 0.1666666667em 0;
    font-weight: 500;
  }

  .block-features__item-subtitle {
    color: #6c757d;
    font-size: 15px;
    line-height: 1.25;
    padding: 0.1111111111em 0;
  }

  @media (max-width: 550px) {
    .block-features__list {
      display: block;
    }

    .block-features__item {
      width: unset;

    }

  }
}


@media (max-width: 320px) {
  .block-features {
    width: 100vw;
  }
}

//block feature css ends
.container {
  max-width: calc(100vw - 100px);
}

a:hover {
  color: $primary-color;
}

.block_homepage {
  min-height: 500px;
  position: relative;
}

.btn {
  font-weight: 500;
  border: none;
  border-radius: 2px;
  font-weight: 500;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  border-color: $primary-color;
  background: $primary-color;
}

.btn-secondary {
  border-color: $secondary-color;
  background: $secondary-color;
  color: $text-black;
}

.btn-secondary:hover {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
}

.btn-primary {
  border-color: $primary-color;
  background: $primary-color;
}

button:focus {
  outline: 0;
}

.btn-primary:hover {
  border-color: #333;
  background: #333;
  color: #fff;
}

.btn-outline-primary {
  border: 1px solid $primary-color;
  color: $primary-color;
}

.btn-outline-primary:hover {
  background-color: $primary-color;
  color: $white;
  border-color: transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border: 1px solid #999999;
  background-color: #999999;
}

.block-space--layout--before-footer {
  height: 72px;
}

.card {
  background-color: #fff;
  box-shadow: 0px 2px 10px #0000001a;
  border: none;
  border-radius: 0;
}

.card-body--padding--2 {
  padding: 2rem;
}

.card-title--lg {
  font-size: 28px;
}

.card-divider {
  height: 1px;
  background: #ebebeb;
}

.MuiDialog-paperFullWidth,
.MuiDialog-paperWidthMd {
  max-width: 800px !important;
  min-height: 450px;
  min-width: 800px;
}

.MuiDialogContentText-root {
  margin-bottom: unset !important;
}

.MuiPaper-rounded {
  border-radius: unset !important;
}

#alert-dialog-slide-title>button.Component-closeButton-32>span.MuiIconButton-label>svg>path {
  fill: #ccc;
}

.status-badge {
  display: inline-block;
  vertical-align: middle;
  cursor: default;
}

.status-badge--style--success .status-badge__body {
  background-color: #e2f2da;
  color: #44782a;
}

.status-badge__body {
  position: relative;
  min-height: 23px;
  border-radius: 11.5px;
  min-width: 31px;
}

.status-badge__text {
  padding-left: 12px;
  padding-right: 12px;
}

.status-badge__text {
  font-size: 13px;
  line-height: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}

//image slider css starts

.image-gallery-thumbnail {
  width: 66px !important;
  height: 66px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 58px !important;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 250px;
  max-height: 250px;
  width: 250px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 4px solid $primary-color !important;
}

.facebook-plugin {
  border-top: 1px solid #ebebeb;
  padding: 30px 20px;
}

//image slider css ends

.block-header__title {
  font-size: 2.25rem;
  font-weight: 700;
  padding-bottom: 20px;
  text-align: center;
}

label {
  margin-bottom: 0.25rem;
  font-size: 15px;
}

.MuiRating-label {
  font-size: 25px;
}

.MuiRating-root {
  font-size: 16px !important;
}



.dashboard__orders {
  width: 100%;

  .pagination-page .products-view__pagination {
    box-shadow: unset;
  }

  // .card-table tbody tr:hover>*,
  // .card-table tfoot tr:hover>* {
  //   background-color: #f7f7f7;
  // }

  a {
    color: $primary-color;
  }
}

.alert-dialog-text {
  color: #262626;
  font-weight: 500;
}

//empty block

.block-empty__body {
  text-align: center;
}

.block-empty__title {
  margin-top: 12px;
  font-size: 36px;
  font-weight: 700;
}

.block-empty__message {
  margin-top: 16px;
}

.block-empty__action {
  margin-top: 32px;
}

.btn-muted:hover {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
}

.card-title {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 2rem;
  margin-top: -4px;
}

//empty block ends

.form-check {
  position: relative;
  display: block;
  padding-left: 1.5rem;
}

.form-check-input {
  margin-left: -1.5rem;
}

.input-check {
  display: inline-block;
}

.form-check-input {
  position: absolute;
  margin-top: 0.1875rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.input-check__input {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.input-check__input:checked~.input-check__box {
  background: #32c2ef;
  box-shadow: 0px 2px 10px #0000001a;
}

.input-check__box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 1.2px;
  background: #fff;
  border: 1px solid #0000001a;
  transition: background 0.15s, box-shadow 0.15s;
}

.input-check__input:checked~.input-check__icon {
  fill: #fff;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-check__icon {
  pointer-events: none;
  position: absolute;
  left: 2px;
  top: 4px;
  fill: #fff;
  transform: scale(0);
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
}

.input-check__icon svg {
  display: block;
  width: 100%;
  height: 100%;
}

svg {
  overflow: hidden;
}

.form-group {
  margin-bottom: 1rem;
}

//sidebar starts

.block-split__item-sidebar {
  width: 360px;
}

@media (max-width: 991.98px) {
  .block-split__item-sidebar {
    width: 100% !important;
    margin-top: 48px;
    order: 1;
  }

  .article {
    margin-left: unset;
  }

  .product-categories-page .block-split__item {
    width: 100%;
    margin-left: unset;
  }

  .block-split__row {
    flex-direction: column;
  }

  .product-caytegory-pagination {
    margin: unset;
    width: 100%;
  }
}

//sidebar-ends

//block-map

@media (max-width: 767.98px) {
  .block-map__body {
    height: 340px;
  }
}

@media (max-width: 991.98px) {
  .block-map__body {
    height: 360px;
  }
}

@media (max-width: 1199.98px) {
  .block-map__body {
    height: 380px;
  }
}

@media (max-width: 1399.98px) {
  .block-map__body {
    height: 400px;
  }
}

.block-map__body {
  display: block;
  position: relative;
  height: 420px;
}

//block map ends

.center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.invalid-feedback {
  display: block;
}

.error-border {
  border-color: #dc3545;
}


// block space css starts
.block-space--layout--before-footer {
  height: 80px;
}

.block-space--layout--after-header {
  height: 84px;
}

//block space css ends


//pagination css starts
.pagination-page {
  .products-view__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin: 10px;
    padding: 0.75rem 1rem;
    flex-wrap: wrap;
  }

  .products-view__pagination-legend {
    font-size: 0.9375rem;
    padding: 0 0.375rem;
  }

  .MuiPaginationItem-outlined {
    border: unset;
    background: #dee2e6;
    min-width: 30px;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #32c2ef !important;
    color: #fff;
  }
}


//pagination css ends

.shipping-methods__item-header {
  display: flex;
  line-height: 18px;
  margin: 0;
  padding: 5px 0 5px 25px;
  cursor: pointer;
  color: #999;
  text-align: left;
  width: auto;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    visibility: none;
  }
}

.block-empty {
  height: 100%;
  position: relative;
  text-align: center;
  padding: 10px;
  min-height: 350px;
}

.block-empty__title {
  margin-top: 12px;
  font-size: 36px;
  font-weight: 700;
}

.block-empty__message {
  margin-top: 16px;
}

.loading-overlay {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;

  .fa-spinner {
    color: #000;
  }
}

.product-categories-page {
  min-height: 350px;
  position: relative;
}

.dashboard__orders {
  th {
    font-weight: 500;
  }

  a {
    color: $white;
  }

  .order-id {
    color: $primary-color;
  }
}

.table-title-1 {
  background-color: $primary-color;
  padding: 1rem;
  color: $white;


  a {
    color: $white;
    text-decoration: underline;
  }
}

.unset-th-border {
  border-right-color: $primary-color !important;
}

.table-title {
  background-color: $primary-color;
  padding: 1rem;
  color: $white;
  display: flex;
  justify-content: space-between;

  a {
    color: $white;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    max-width: calc(100vw - 50px);
  }
}


@media only screen and (max-width: 1000px) {
  .container {
    max-width: 100vw;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 10px 0;
  }
}


@media only screen and (max-width: 400px) {
  .product-categories-page .product-grid {
    grid-template-columns: repeat(1, 1fr)
  }

  .header_topbar_msg {
    position: unset;
  }
}

.indicator__button:hover {
  background-color: #f2f2f2 !important;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.MuiDrawer-paper {
  width: 270px !important;
}

.order-details-table {
  min-height: 300px;

  .center-div {
    top: 40%;
  }
}

.card-table {
  min-height: 300px;
  position: relative;
}

.search-button:hover {
  background-color: #32c2ef;
  color: #fff;

}



.van-image {
  img {
    height: 30px;
    width: 30px;
    object-fit: scale-down;
  }
}




#google-autocomplete .MuiInputBase-input {
  padding: 0 0 0 5px;
  font-size: 14px;
}

#google-autocomplete .MuiFormLabel-root.Mui-focused {
  color: #495057;
}

#google-autocomplete .MuiFormLabel-root {
  font-size: 14px;
  margin-bottom: unset;
}

#google-autocomplete .MuiAutocomplete-inputFocused {
  border-color: #ced4da;
}


#google-autocomplete .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

}

#google-autocomplete .MuiOutlinedInput-root:hover {
  outline: none;
}

@media (max-width: 470px) {

  .search-input {
    width: 200px;
  }

  .logo-image img {
    height: 45px !important;
  }

}

@media (max-width: 525px) {
  .search-input {
    width: 50%;
  }

}

@media (max-width: 800px) {

  .product-name-ellipsis {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 482px) {
  .logo-image img {
    height: 45px !important;
  }
}


@media (max-width: 425px) {

  .search-input {
    width: unset;
  }

  .cart .cart-table__column {
    padding: 5px !important;
    font-size: 12px;
    width: max-content;
  }

  .cart .cart__table {
    overflow: unset;
    width: calc(100vw - 10px);
  }

  .cart__totals .card {
    width: calc(100vw - 10px);

  }
}

@media (max-width: 992px) {
  .navbar-brand {
    margin-left: 10px;
  }
}



.navbar {
  background-color: #fff;
}



.fixed-top {
  padding: unset;
}


.form-control:disabled,
.form-control[readonly] {
  background-color: #F1F1F1;
  opacity: 1;
}

.remove-icon {
  color: red;
}


.cart-table__remove,
.wishlist__remove {
  margin-bottom: 60px;

}


//order received css starts

.order-received {
  .card {
    min-height: 400px;
    padding: 20px;
    margin: 50px 0;
  }

  .order-details-table {
    width: max-content;
    margin: 0 auto;
  }

  .order-sub-title {
    color: #2d2d2d;
    font-weight: 500;
  }

  .check-icon {
    color: #32c2ef;
    border: 2px solid;
    border-radius: 50%;
    font-weight: 600;
    margin: 5px;
    font-size: 30px;
  }

  .close-icon {
    color: #dc3545;
    border: 2px solid;
    border-radius: 50%;
    font-weight: 600;
    margin: 5px;
    font-size: 30px;
  }

  thead th {
    border-bottom: unset;
    font-weight: 500;
  }

  .product-table {
    th {
      background-color: #32c2ef;
      color: #fff;
    }

    th,
    td {
      text-align: center;
    }
  }

  .product-img {
    max-height: 150px;
    max-width: 150px;
  }

  .address {
    label {
      margin-right: 5px;
      color: #818181;
      font-size: 14px;
    }

    div {
      font-size: 14px;
    }



    h5 {
      color: #32c2ef;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 5px;
    }
  }

  .address.card {
    margin: 20px 0 20px 0;
    min-height: unset;
  }

  .amount-table {
    width: max-content;
    margin-left: auto;
    min-height: max-content;

    th {
      color: #818182;
      font-weight: 500;
      text-align: left;
    }

    td {
      font-weight: 500;
    }

    th,
    td {
      border: unset;
    }
  }

  @media (max-width: 767.98px) {

    .product-img {
      max-height: unset;
      max-width: 100%;
    }

    .order-details-table {
      min-height: unset;
    }

    .card {
      padding: 10px;
    }

    .amount-table {
      margin: unset;
    }

    .table {
      font-size: 14px;

      td,
      th {
        padding: 0.3rem;
      }
    }

    .order-details-table {
      width: unset;
    }


  }

  .amount-table.card {
    min-height: unset;
    min-height: unset;
  }
}


.order-cancel .card {

  min-height: 100px;
  padding: 20px;
  margin: 50px;
}

//order received css ends



//product view css starts
.product-view {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12941176470588237) !important;
  padding: 0.75rem 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;

  .layout-switcher__list {
    display: flex;
  }

  .layout-switcher__button--active {
    color: $primary-color;
  }

  .layout-switcher__button {
    padding: 7px;
    margin: 0;
    border: none;
    display: block;
    border-radius: 2px;
    background-color: transparent;
    color: #95a0a6;
  }

  .layout-switcher__button svg {
    display: block;
    fill: currentColor;
  }

  .view-options__layout,
  .view-options__legend,
  .view-options__select {
    margin-right: 20px;
  }

  .view-options__legend {
    white-space: nowrap;
    width: 225px;
  }

  .view-options__spring {
    flex-grow: 1;
  }

  .view-options__select {
    display: flex;
    -moz-box-align: center;
    align-items: center;
  }

  .view-options__select>label {
    margin-right: 10px;
  }

  .view-options__select>label {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .layout-switcher__button--active {
    color: $primary-color;
  }

  @media (max-width: 486px) {
    .view-options__select {
      margin-top: 10px;
    }
  }
}

//product view css ends


//prduct quick view css starts
.product-quick-view {
  min-width: 600px;
  min-height: 400px;
  position: relative;
}

.quickview__body {
  padding: 32px 22px 10px;
  display: flex;
  flex-direction: row;
}

.quickview__product-actions-item--wishlist {
  margin: 4px 0 0 0px !important;
}

.loading-overlay {
  background-color: unset;
}

.quickview__gallery {
  margin-right: 28px;
  // width: 320px;
  flex-shrink: 0;
}



.quickview__product {
  flex-grow: 1;
}

.quickview__product-name {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-top: -2px;
  color: #262626;
}


.quickview__sku-label,
.quickview__sku-value {
  font-size: 14px;
  line-height: 28px;
  color: #262626;
}

.quickview__sku-label {

  font-weight: 700;
  color: #262626;
  margin-right: 2px;
}


.quickview__product-description {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
}

.quickview__product-prices-stock {
  display: flex;
  align-items: center;
  margin: 12px 0 16px;
}

.quickview__product-prices {
  line-height: 1;
  padding: 6px 0;
}

.quickview__product-stock {
  margin-left: 12px;
}

.quickview__product-price {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #262626;
}

.quickview__product-actions {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.quickview__product-actions-item--quantity {
  width: 100px;
}

.quickview__product-actions-item {
  margin: 4px;

  .product-card__wishlist-icon svg {
    fill: #32c2ef;
  }
}

.input-number {
  display: block;
  width: 100%;
  position: relative;
}

.input-number__input {
  display: block;
  width: 100%;
  min-width: 88px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.input-number__add,
.input-number__sub {
  right: 1px;
}

.input-number__sub {
  left: 1px;
}

.input-number__add,
.input-number__sub {
  position: absolute;
  height: 100%;
  width: 24px;
  top: 0;
  cursor: pointer;
  user-select: none;
  opacity: 0.3;
  transition: opacity 0.18s;
}

.input-number__add:before,
.input-number__sub:before {
  width: 8px;
  height: 2px;
}

.input-number__add:after,
.input-number__add:before,
.input-number__sub:after,
.input-number__sub:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: currentColor;
}

.input-number__add:after {
  width: 2px;
  height: 8px;
}

.quickview__see-details {
  border-radius: 0 0 2.5px 2.5px;
  border-top: 1px solid #ebebeb;
  display: block;
  text-align: center;
  color: #6c757d;
  font-size: 15px;
  height: 52px;
  line-height: 50px;
  transition: background 0.15s, border-color 0.15s;
}

.MuiDialogContent-root {
  padding: unset !important;
}


@media (max-width: 700px) {
  .quickview__body {
    flex-direction: column;
  }

  .quickview__gallery {
    width: 100%;
    margin-bottom: 28px;
  }
}


//product quick view css ends


