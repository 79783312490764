@import "../variables.scss";

.product-category-sidebar {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12941176470588237) !important;
  background-color: #fff;
  height: calc(100% - 10px);
  border-radius: 4px;
  .widget__header {
    padding: 1.375rem 1.5rem;
  }
  .widget__header h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .MuiPaper-elevation1 {
    box-shadow: unset;
  }

  .MuiTypography-body1 {
    padding: 6px 10px;
    font-weight: 700;
    font-size: 15px;
  }

  .MuiSvgIcon-root {
    fill: #ccc;
  }
  .filter__container {
    padding: 10px 0.5rem 16px;
  }
  .filter-category__list {
    list-style: none;
    padding: 0;
    padding-left: 0 !important;
    margin: 0;
    font-size: 15px;
    line-height: 18px;
  }
  .filter-category__item {
    padding: 5px 0;
    display: flex;
  }
  .filter-category__item > a,
  .filter-category__item > div {
    padding-right: 10px;
    color: #95a0a6;
    font-weight: 500;
    transition: color 0.15s;
    cursor: pointer;
  }

  .filter-category-list {
    padding-left: unset !important;
  }

  .MuiAccordionDetails-root {
    padding: 0 16px 16px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: unset;
  }

  .MuiAccordionSummary-root {
    max-height: 35px;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 35px;
    margin-top: 10px;
  }

  .filter-category__item_active {
    color: #32c2ef !important;
  }

  .MuiAccordion-root {
    border-top: 1px solid #ebebeb;
    margin: unset !important;
  }

  .filter-category__item > a:hover,
  .filter-category__item > div:hover {
    color: $primary-color;
    text-decoration: none;
    transform: scale(1.05);
  }

  .MuiAccordion-root:before {
    background-color: #ebebeb;
    height: 0.5px;
  }

}

@media only screen and (max-width: 1300px) {
  .block-split__item-sidebar {
    width: 300px !important;
  }
}

