.submit-testimonial-btn {
  position: sticky;
  bottom: 55%;
  left: 85%;
  z-index: 10;
}

.testimonials-card {
  .post-card--layout--list {
    background-color: #fff;
    box-shadow: 0px 2px 10px #0000001a;
    display: flex;
    padding: 20px;
  }

  .block-reviews__item-text {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }



  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .post-card__date:before {
      display: block;
      width: 36px;
      margin-top: 14px;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 768px) {
    .post-card__date:before {
      content: "";
      height: 1px;
      background: currentColor;
      opacity: 0.6;
    }
  }

  .posts-list__item {
    margin-left: 10px;
    margin-bottom: 25px;

  }

  .post-card__title {
    color: inherit;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    .post-card--layout--list {
      display: block;
    }

    .post-card__image {
      height: 250px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .post-card__excerpt {
      max-height: 78px;
    }
  }

  @media (min-width: 768px) {
    .post-card__excerpt {
      overflow: hidden;
      margin-bottom: auto;
    }
  }

  .post-card__image {
    max-width: 100%;
    height: auto;
  }

  .post-card__image,
  .post-card__image img {
    width: 200px !important;
    height: 200px;
    border-radius: 50%;
    margin-right: 30px;
  }

  @media (min-width: 768px) {
    .post-card__image {
      display: flex;
      flex-shrink: 0;
    }

    .post-card__image img {
      width: 100%;
      height: 100%;
    }
  }

  .post-card__content {
    padding: 20px 26px 22px;
  }

  .post-card__date,
  .post-card__date a {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
  }
}


.file-input {
  input {
    height: unset;
  }
}


.testimonial__body {
  padding: 0px 32px 10px;
  display: flex;
  flex-direction: row;

  .block-testimonial__item {
    background-color: #fff;
    margin: 0 auto;
    border-radius: 6px;
    padding: 20px 10px;
  }

  .block-testimonials__grid {
    display: grid;
    align-items: center;
    grid-template-columns: 135px auto;
  }

  .block-reviews__item-text {
    overflow: unset;
    text-overflow: unset;
    -webkit-line-clamp: unset;
  }

  .block-testimonial__item-avatar,
  [dir="rtl"] .block-testimonial__item-avatar {
    margin-left: 12px;
    margin-right: 12px;
  }

  .block-testimonial__item-avatar {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .block-testimonial__item-avatar img {
    max-width: 100%;
  }

  .block-testimonial__item-avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #707070;
  }

  .block-testimonial__field {
    color: #32c2ef;
    font-weight: 600;
  }

  .block-testimonial__address {
    font-size: 15px;
    color: #707070;
  }
}