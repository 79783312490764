.block-card-vangain-container {
    min-height: 300px;
    z-index: -1;


    .block-card--layout--list {
        display: flex;
        padding-top: 100px;
        flex-wrap: wrap;
    }

    .block-card--layout--list .block-card__image {
        width: 280px;
        height: 220px;

        display: flex;
        flex-shrink: 0;
        margin-left: auto;
    }

    .block-card--layout--list .block-card__content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: flex-start;
    }

    .block-card__content {
        max-width: 600px;
        margin: auto;
    }

    .block-card--layout--list .block-card__title {
        margin-bottom: 10px;
    }


    .block-card--layout--list .block-card__title h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        margin-bottom: 25px;

        span {
            color: #32c2ef;
        }
    }

    @media (max-width: 1200px) {
        .block-card__content {
            width: 400px;
        }
    }

    @media (max-width: 960px) {
        .block-card__content {
            width: 300px;
        }
    }

    @media (max-width: 320px) {
        .block-card__title {
            width: 600px;
            margin-left: 20px;
        }
    }



    @media (max-width: 712px) {
        .block-card--layout--list .block-card__image {
            width: unset;
            margin: auto;
        }

        .block-card__content .block-card__image {
            width: auto;

            h3 {
                text-align: center;
            }
        }
    }

}


.block-finder-outer-div {
    background-color: #fff;
    min-height: 286px;

    display: flex;

    .block-finder {
        position: relative;
        min-height: 160px;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        background: #333;
        border-radius: 10px;
        max-width: 870px;
        margin: auto;
        padding: 35px 60px;
    }

    .block-finder-input {
        width: 100%;

        input {
            height: 44px;
        }
    }

    .block-finder-sku {
        .block-finder__button {
            margin: unset;
            margin-left: 7px;
        }
    }

    .block-finder_filter {
        background-color: #32c2ef;
        width: 500px;
        border-radius: 5px;
        position: absolute;
        z-index: 10;
        top: -15%;
        left: 7%;
    }

    .block-finder_filter-grid label input:checked+span {
        background-color: #e7e7e7;
        content: "aghh";
    }

    .block-finder_filter-grid label span {
        display: flex;
        -moz-box-align: center;
        align-items: center;
        padding: 0.375em 0.75em 0.375em 0.375em;
        background-color: #8dd5eb;
        border-radius: 99em;
        -webkit-transition: 0.25s ease;
        -moz-transition: 0.25s ease;
        transition: 0.25s ease;
    }

    .block-finder_filter-grid label {
        display: flex;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        margin: unset;
    }

    .block-finder_filter-grid {
        display: flex;
        -moz-box-align: center;
        align-items: center;
        height: 45px;
        padding: 8px;
        -moz-box-pack: space-evenly;
        justify-content: space-evenly;
    }

    .block-finder_filter-grid label input[type="radio"]:checked+span:before {
        background-image: url("../../../images/check-circle-fill.svg");
        background-size: cover;
    }

    .block-finder_filter-grid label span:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        border: 1px solid;
        width: 1.5em;
        height: 1.5em;
        border-radius: 15px;
        margin-right: 0.375em;
        transition: 0.25s ease;
    }

    .block-finder_filter-grid label input {
        position: absolute;
        left: -9999px;
    }

    .vehicle-select__list :first-child {
        margin-right: 14px;
    }

    .vehicle-select__item {
        position: relative;
        -moz-box-flex: 1;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 220px;
    }

    .block-finder__body {
        color: #fff;
        z-index: 2;
        margin-top: auto;
    }

    .block-finder__form {
        display: flex;
        margin: -7px;
        align-items: flex-end;
    }

    .vehicle-select__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .vehicle-select__item {
        position: relative;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 220px;
    }

    .vehicle-select__item-control {
        padding-left: 16px;
        padding-right: 29px;
        background-position: right 12px center;
        text-align: left;
    }

    .vehicle-select__item-control {
        width: 100%;
        height: 44px;
        border: none;
        appearance: none;
        cursor: pointer;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 2px;
        color: #262626;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 12px;
        background-image: url("../../../images/down.png");
        transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
    }

    .block-finder__button {
        height: 44px;
        min-height: 44px;
        border-radius: 2px;
        border: none;
        padding: 0 40px;
        text-transform: uppercase;
        transition: background 0.2s, color 0.2s;
        background-color: #6c757d;
        color: #fff;
    }

    .block-finder__button:hover {
        background-color: #32c2ef;
    }

    .block-finder__button,
    .block-finder__select {
        margin: 7px;
    }

    .block-finder-container {
        position: relative;
        margin: auto;
        margin-top: 75px;
    }

    @media (max-width: 600px) {
        .block-finder__form {
            flex-wrap: wrap;
        }

        .block-finder_filter {
            width: max-content;
        }

        .block-finder_filter-grid {
            flex-direction: column;
        }

        .block-finder {
            padding: 20px;
        }
    }

    @media (max-width: 550px) {
        .block-finder_filter {
            width: 400px;
        }
    }

    @media (max-width: 320px) {
        .vehicle-select__item-control {
            width: 170px;
        }
    }

    @media (max-width: 320px) {
        .block-finder-container {
            margin-left: 65px;
        }
    }

    @media (max-width: 600px) {
        .block-finder_filter-grid {
            height: 100%;
        }

        .block-finder_filter {
            width: 320px;
            height: 150px;
            width: 100%;
        }

        .block-finder_filter {
            top: -75px;
            left: 0;
        }

        .block-finder_filter-grid label input:checked+span,
        .block-finder-input {
            margin: 10px 0 10px 0;
        }

        .block-finder {
            min-height: 315px;
        }

        .block-finder-sku {
            margin: auto;
        }
    }

}



// .block-reviews-margin {
.block-reviews__content {
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
}

.block-reviews__header {
    text-align: center;
    position: relative;
    padding: 14px 32px 0;
    margin: 0 auto -28px;
    z-index: 1;
}


.block-reviews__subtitle {
    color: #262626;
    line-height: 1;
    font-weight: 600;
    font-size: 24px;
}

.block-reviews__controls {
    display: flex;
    -moz-box-align: center;
    align-items: center;
}

.block-reviews__arrow--prev {
    left: -49.7957px;
}

.block-reviews__arrow {
    position: absolute;
    bottom: 3px;
}

.arrow {
    display: block;
}

.block-reviews__arrow .arrow__button {
    height: 31px;
    padding: 0 24.89785px;
}

.block-reviews__arrow .arrow__button:before {
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
}

[dir="ltr"] .block-reviews__arrow .arrow__button:before {
    left: 8.948925px;
}

.arrow__button svg {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    transform: scaleX(1);
}

.arrow__button svg {
    fill: currentColor;
    display: block;
}

.arrow__button {
    display: flex;
    position: relative;
    z-index: 0;
    align-items: center;
    border: none;
    background: transparent;
    pointer-events: none;
    transition: color 0.15s;

    svg {
        fill: currentColor;
        display: block;
    }

    &:focus {
        outline: none;
    }

    &:before {
        position: absolute;
        display: block;
        content: "";
        height: 100%;
        transform-origin: center center;
        z-index: -1;
        border-radius: 2px;
        pointer-events: auto;
        transition: background 0.15s;
    }



    &:active {

        &,
        &:before {
            transition-duration: 0s;
        }
    }

    // this is to avoid chrome rendering bug
    &:after {
        position: absolute;
        display: block;
        content: "";
        top: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        transition: background 0.2s;
        opacity: 0.01;
    }

    &:hover:after {
        background: black;
    }

    &:active:after {
        background: #fff;
        transition-duration: 0s;
    }
}

.block-reviews__link {
    -moz-box-flex: 1;
    flex-grow: 1;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 0;
}

.block-reviews__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50%;
    z-index: -1;
}

.block-reviews__item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.block-reviews__link a {
    color: #6c757d;
    -webkit-transition: color 0.12s;
    -moz-transition: color 0.12s;
    transition: color 0.12s;
}

.block-reviews__header {
    text-align: center;
    position: relative;
    padding: 14px 32px 0;
    margin: 0 auto -28px;
    z-index: 1;
}

.arrow__button:before {
    background: #32c2ef;
}

.arrow__button svg {
    fill: white;
    display: block;
}

.block-reviews__arrow .arrow__button:before {
    width: -webkit-calc(100% - 17.89785px);
    width: -moz-calc(100% - 17.89785px);
    width: calc(100% - 17.89785px);
}

.block-reviews__arrow .arrow__button:before {
    left: 8.948925px;
}

.block-reviews__header-decor {
    position: absolute;
    width: 100%;
    bottom: 1px;
    left: 0;
    z-index: -1;
}

.decor {
    display: block;
}

.block-reviews__header-decor .decor__body {
    height: 38px;
    margin: 0 -10px -10px;
}

.block-reviews__header-decor .decor__end,
.block-reviews__header-decor .decor__start {
    background-color: #fff !important;
}

.block-reviews__header-decor .decor__end {
    border-bottom-right-radius: 2px;
}

.block-reviews__header-decor .decor__center {
    height: 28px;
    width: 34.3316px;
}

.decor--type--center .decor__body {
    overflow: hidden;
    position: relative;
}

.block-reviews__header-decor .decor__start {
    left: 20px;
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
}

.block-reviews__header-decor .decor__end,
.block-reviews__header-decor .decor__start {
    height: 28px;
    width: -webkit-calc(50% - 26.1658px);
    width: -moz-calc(50% - 26.1658px);
    width: calc(50% - 26.1658px);
}

.block-reviews__header-decor .decor__end,
.block-reviews__header-decor .decor__start {
    background-color: #fff !important;
}

.block-reviews__header-decor .decor__end {
    border-bottom-right-radius: 2px;
}

.block-reviews__header-decor .decor__end {
    right: 20px;
    -webkit-transform: skewX(-30deg);
    -moz-transform: skewX(-30deg);
    transform: skewX(-30deg);
}

.decor--type--center .decor__end,
.decor--type--center .decor__start {
    position: absolute;
}

.block-reviews__header-decor .decor__center,
.block-reviews__header-decor .decor__end,
.block-reviews__header-decor .decor__start {
    background: #fafafa;
}

.decor__center {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

.block-reviews__arrow--prev {
    left: -49.7957px;
}

.block-reviews__arrow--next {
    right: -50.7957px;
}

.block-reviews__arrow .arrow__button:before {
    -webkit-transform: skewX(30deg);
    -moz-transform: skewX(30deg);
    transform: skewX(30deg);
}

.block-reviews__arrow--next .arrow__button:before {
    -webkit-transform: skewX(-30deg);
    -moz-transform: skewX(-30deg);
    transform: skewX(-30deg);
}

.arrow__button:hover:before {
    background: #333;
}

.block-reviews__list .slick-slide {
    padding: 0 10px;
}

.block-reviews__body {
    padding-top: 80px;
    padding-bottom: 10px;
    position: relative;
    min-height: 300px;
}

.block-reviews__list .slick-dots {
    width: auto;
    position: static;
    padding: 0;
    font-size: 0;
    list-style: none;
    margin: 18px 0 0;
    text-align: center;
}

.block-reviews__list {
    max-width: 65%;
    margin: auto;
}

.block-reviews__list .slick-dots button {
    width: 10px;
    height: 10px;
    padding: 0;
    border: 1px solid #fff;
    background: transparent;
    box-shadow: 0px 2px 10px #0000001a;
    border-radius: 6px;
}

.block-reviews__list .slick-dots .slick-active button {
    background: #fff;
}

.slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: " ";
    text-align: center;
    opacity: 0.25;
    color: #000;
}

//block review card css start
.block-reviews__grid {
    display: grid;
    -moz-box-align: center;
    align-items: center;
    grid-template-columns: 85px auto;
}

.block-reviews__item {
    background-color: #fff;
    box-shadow: 0px 2px 10px #0000001a;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 6px;
    padding: 20px 10px;
    height: 180px;
}

.block-reviews__item-author {
    color: #32c2ef;
    font-size: 18px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.block-reviews__item-content {
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 24px 15px;
}

.block-reviews__item-avatar,
[dir="rtl"] .block-reviews__item-avatar {
    margin-left: 12px;
    margin-right: 12px;
}

.block-reviews__item-avatar {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.block-reviews__item-avatar img {
    max-width: 100%;
}

.block-reviews__item-avatar img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #707070;
}

//block review card css ends

@media (max-width: 320px) {
    .block-reviews__body {
        width: 100vw;
    }
}

@media (max-width: 600px) {
    .block-reviews__list {
        max-width: 600px;
    }

    .block-reviews__subtitle {
        font-size: 20px;
        // margin-top: 70px;
    }
}


.block-slideshow {
    .block-slideshow__item-image {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }

    .block-slideshow__carousel .slick-dots {
        width: max-content;
        right: 0;
        position: absolute;
        bottom: 26px;
        padding: 0;
        font-size: 0;
        list-style: none;
        margin: 0;
        text-align: center;
    }

    .reflect-rtl {
        transform: scaleX(1);
    }

    .block-slideshow__carousel .slick-dots .slick-active button {
        background: #fff;
    }

    .block-slideshow__carousel .slick-dots button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid #fff;
        border-radius: 5px;
        background: transparent;
    }

    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .block-slideshow__item,
    .block-slideshow__item:hover {
        color: #262626;
    }

    .block-slideshow__item {
        border-radius: 2.5px;
        overflow: hidden;
        padding: 68px 80px;
        min-height: 357px;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;
    }

    @media (max-width: 320px) {
        .block-slideshow__carousel {
            width: 300px;
        }
    }

}

.block-card-vanagain-layout {
    min-height: 300px;
}