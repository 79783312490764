.cart-discount-points {
  font-size: 14px;
  font-weight: 500;
}

.cart {
  display: grid;
  grid-template-columns: 3fr 360px;
  grid-gap: 30px;

  .cart-table__coupon-form {
    max-width: 400px;
  }

  .cart__totals {
    max-width: 300px;
  }



  .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #ebebeb;
    border-radius: 50%;
  }

  input:checked~.check {
    background-color: #32c2ef;
  }

  .check:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.check:after {
    display: block;
  }

  .input-number__add,
  .input-number__sub {
    right: 1px;
  }

  .input-number__add,
  .input-number__sub {
    position: absolute;
    height: 100%;
    width: 24px;
    top: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.3;
    -webkit-transition: opacity 0.18s;
    -moz-transition: opacity 0.18s;
    transition: opacity 0.18s;
  }

  .check:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .cart-table__table {
    width: 100%;
    border-spacing: 0;
  }

  .cart-table__head {
    font-size: 13px;
    text-transform: uppercase;
  }

  .cart-table__column--product {
    line-height: 1.25;
  }

  .cart-table__column--price {
    width: 130px;
  }

  .cart-table__column--quantity {
    padding-left: 36px;
    width: 150px;
    text-align: center;
  }

  .cart-table__column--total {
    width: 130px;
    text-align: right;
  }

  .cart-table__column--image .image {
    width: 80px;
  }

  .image--type--category .image__body,
  .image--type--product .image__body {
    padding-bottom: 100%;
  }

  .image--type--category .image__body,
  .image--type--product .image__body {
    display: block;
    position: relative;
    width: 100%;
  }

  .image--type--category .image__tag,
  .image--type--product .image__tag {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .cart-table__product-name {
    color: inherit;
  }

  .cart-table__product-name:hover {
    text-decoration: unset;
    color: #32c2ef;
  }

  .calculate-shipping,
  .calculate-shipping:hover {
    color: #32c2ef;
    cursor: pointer;
  }

  @media (min-width: 992px) {
    .cart-table__coupon-form {
      width: 360px;
    }
  }


  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }

  .form-row {
    display: block;
  }

  .cart__totals-table {
    width: 100%;
    max-width: 300px;
    margin-bottom: 32px;
    font-size: 15px;
  }

  .cart__totals-table tr:after {
    display: block;
    content: "";
    clear: both;
  }

  .cart__totals-table td {
    text-align: right;
  }

  .cart__totals-table td,
  .cart__totals-table th {
    padding: 0;
  }

  .cart__totals-table tfoot {
    font-size: 20px;
    border-top: 1px solid #ebebeb;
  }

  .cart__totals-table tbody tr:last-child>* {
    padding-bottom: 10px;
  }

  .cart__totals-table th {
    float: left;
  }

  .cart__totals-table tfoot td,
  .cart__totals-table tfoot th {
    padding-top: 12px;
  }

  .cart__totals-table tfoot th {
    font-weight: 500;
  }

  .cart__totals-table tbody tr>* {
    padding-top: 8px;
  }

  @media (max-width: 1399.98px) {
    .cart__totals {
      margin-left: auto;
    }
  }

  @media (max-width: 767.98px) {
    .cart-table__actions {
      display: block;
    }
  }

  @media (max-width: 991.98px) {
    .cart-table__actions {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
    }

    .cart-table__coupon-form,
    .cart-table__update-button {
      padding: 16px;
    }
  }

  @media (max-width: 767.98px) {
    .cart-table__update-button {
      border-top: 1px solid #ebebeb;
      text-align: left;
    }

    .cart-table__column--image .image {
      width: unset;
    }

    .input-number__input {
      padding: unset;
      min-width: 70px;
    }

    .cart-table__column {
      padding: 5px 8px !important;
    }


  }

  @media (max-width: 767.98px) {
    .cart-table__actions {
      display: block;
    }
  }

  @media (max-width: 998px) {
    .cart__totals {
      margin-left: unset;
      margin-top: 20px;
    }


  }

  .cart__totals {
    min-width: 360px;
    flex-shrink: 0;
  }

  @media (min-width: 992px) {
    .cart-table__foot td {
      padding: 20px 24px;
    }
  }

  @media only screen and (max-width: 1115px) {
    .cart {
      display: block;
    }
  }


  @media (min-width: 992px) {
    .cart-table__actions {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 992px) {
    .cart-table__column:first-child {
      padding-left: 28px;
    }
  }

  @media (min-width: 992px) {
    .cart-table__column--image {
      width: 1px;
      text-align: center;
    }
  }

  @media (min-width: 992px) {
    .cart-table__column {
      padding: 14px 16px;
      border-bottom: 1px solid #ebebeb;
    }
  }

  @media (min-width: 992px) {
    .cart-table {
      align-self: flex-start;
      background-color: #fff;
      box-shadow: 0px 2px 10px #0000001a;
    }
  }

  @media (max-width: 1399.98px) {
    .cart__table {
      width: 100%;
    }
  }

  .cart__table {
    flex-grow: 1;
    overflow-x: auto;
  }

  .cart-table__column {
    padding: 14px 16px;
    border-bottom: 1px solid #ebebeb;
  }

  @media (min-width: 992px) {

    .cart-table__column:last-child,
    [dir="rtl"] .cart-table__column:first-child {
      padding-right: 28px;
    }
  }

  .cart-table__column--remove {
    padding-left: 0;
  }

  .cart-table {
    align-self: flex-start;
    background-color: #fff;
    box-shadow: 0px 2px 10px #0000001a;
  }

  @media (max-width: 1399.98px) {
    .cart__table {
      width: 100%;
    }
  }

  .cart__table {
    -moz-box-flex: 1;
    flex-grow: 1;
  }
}

@media (max-width: 400px) {
  .cart__totals .card {
    width: 100vw;
  }

  .cart__totals {
    margin-bottom: 20px;
  }
}

.calculate-shipping-popper {
  box-shadow: 0px 2px 10px #0000001a;
  background-color: #fff;
  padding: 10px;
}

#calc_shipping_postcode {
  text-transform: uppercase;
}

.shippingPopover {
  z-index: 9999;
}

@media (max-width: 992px) {
  .cart {
    display: block;
  }

  .cart__table {
    width: 50%;
  }



  .cart__table {
    width: 100%;
    background-color: green;
  }
}

.cart .check {
  border: 1px solid #8f8f8f;
}