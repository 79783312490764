.product-details-page {
  min-height: 500px;

  .product__prices-stock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 22px;
  }

  .product__price {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.04em;
    color: #32c2ef;
  }

  .product-card-full-page {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12941176470588237) !important;
    padding: 28px;
  }

  .product__features-main {
    margin: 20px 0 0;
    font-size: 14px;
    border-radius: 3px;

    ul {
      padding-left: 15px;

      li {
        color: #6c757d;
      }
    }
  }

  .product__features ul {
    list-style: none;
    padding: 0;
    color: #6c757d;
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
  }

  .product__features li:before {
    left: 0;
  }

  .product__features li:before {
    position: absolute;
    display: block;
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background: currentColor;
    top: 12px;
    opacity: 0.85;
  }

  .product__features li {
    padding-left: 11px;
    padding-right: 0;
  }

  .product__features-title {
    font-weight: 500;
    padding-bottom: 12px;
  }

  .product__features li {
    padding-top: 3px;
    padding-bottom: 2px;
    position: relative;
    margin: 0 6px;
    width: calc(100% / 1 - 12px);
  }

  .product__header h1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
  }

  .product__header {
    padding-bottom: 16px;
  }

  .product__info-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .product__meta table {
    width: 100%;
    font-size: 14px;
  }

  .product__meta th {
    padding-right: 12px;
  }

  .product__actions {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
  }

  .product__actions-item--quantity {
    margin-right: 8px;
  }

  .input-number {
    display: block;
    width: 100%;
    position: relative;
  }

  .product__actions-item--quantity {
    width: 100px;
  }

  .product__actions-item--addtocart {
    flex-grow: 1;
  }

  .product__actions-item--wishlist {
    padding-left: 9px;
    padding-right: 10px;
  }

  .product__actions-item--wishlist {

    svg {
      fill: #32c2ef;
    }
  }

  .btn-add-wishlist {
    margin: auto;
    text-align: center;
    margin-top: 10px;

    svg {
      fill: #32c2ef;
    }
  }

  .btn-add-wishlist:hover {
    background-color: #000000;
    color: #fff;

    svg {
      fill: #ffffff;
    }
  }

  .product__info-body {
    padding: 28px 0 24px;
  }

  .product__meta th,
  .product__meta td {
    padding: 3px 0;
  }

  tr:first-child {
    border-bottom: 1pt solid #ebebeb;
  }

  .product__tabs {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12941176470588237) !important;
    margin-top: 15px;
  }

  .product-tabs__item {
    font-size: 22px;
    font-weight: 500;
    color: inherit;
    display: flex;
    padding: 35px 28px 25px;
  }

  .product-tabs__content {
    padding: 0 28px 28px;
  }

  ul {
    padding: unset;
  }

  .product__features {
    height: 100%;
  }

  .product-video {
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 35px;
  }

  #productGalleyMagnify {
    position: absolute;
    top: 0px;
    left: 100%;
    margin-left: 10px;
    width: 336px;
    height: 249px;
    opacity: 1;
    transition: opacity 300ms ease-in 0s;
    pointer-events: none;
    z-index: 2;
  }

  #productGalleyMagnify>div {
    background: #fff;
    border: 1px solid rgb(214, 214, 214);
  }
}

.status-badge__text_out {
  background-color: #ebcbcb;
  border-radius: 10px;
  color: red;
  padding-right: 10px;
  padding-left: 10px;
  
}

@media (max-width: 320px) {
  .product__tabs {
    margin-top: 30px !important;
    margin-left: 30px !important;
  }
}

@media (max-width: 576px) {
  .product-add-to-cart {
    padding-left: 0;
    margin: 25px 0;
  }
}


@media (max-width: 600px) {
  .product-details-page .product-card-full-page {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .product-tabs__content iframe {
    width: 220px !important;
    height: 250px !important;
  }
}