.product-categories-page {
  background-color: #fafafa;
  padding-bottom: 20px;

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .product-loader-div {
    height: 400px;
    padding: 20px;
    position: relative;
  }

  .product-grid,
  .product-desc {
    .products-list__item {
      max-width: 100%;
    }
  }

  .product-desc {
    .products-list__item {
      min-height: 510px;
    }
  }

  .block-split__item {
    margin-left: 30px;
    width: calc(100% - 360px - 30px);
  }

  .product-grid,
  .product-desc {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  @media (min-width: 990px) and (max-width: 1150px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 800px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: unset;
    }

    .product-view .view-options__layout {
      margin-right: 15px;
    }

  }
}



.products-list-table {
  box-shadow: 0px 2px 10px #0000001a;
  background-color: #fff;
  padding: 0.75rem 1rem;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;

  .product-card__name {
    position: relative;
    font-size: 15px;
    line-height: unset;
  }

  .products-list__column--meta {
    width: 140px;
    text-align: center;
    padding: 0 16px;
  }

  .product-card {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .product-card__name {
    width: 100%;
    display: block !important;
  }

  .product-card__meta {
    text-align: center;
    width: 10%;
  }

  .products-list__head {
    display: flex;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .product-card {
    box-shadow: unset;
    border-bottom: 1px solid #ebebeb;
  }

  .products-list__column--image {
    width: 12%;
    box-sizing: content-box;
    text-align: center;
  }

  .products-list__item {
    flex-shrink: 0;
    display: flex;
    width: 100%;
    min-height: unset;
  }

  .product-card__image {
    height: unset;
    width: 10% !important;
    text-align: center;
    margin-right: unset !important;
    padding: 5px;
  }

  .products-list__column--meta {
    width: 12%;
    text-align: center;
    padding: unset;
  }

  .products-list__column--product {
    padding: 0 18px;

    width: 60%;
  }

  .products-list__column--rating {
    margin-left: 1px;
  }

  .products-list__column--rating {
    width: 140px;
    text-align: center;
    padding: 0 16px;
  }

  .products-list__column--price {
    text-align: center;
    width: 12% !important;
  }

  .products-list__column--price {
    width: 136px;
    padding: 0 16px;
    margin: 0 1px;
  }

  .products-list__item+.products-list__item {
    border-top: 1px solid #ebebeb;
  }

  .products-list__item {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    margin: unset;
  }

  .product-card__prices {
    width: 10% !important;
  }

  .product-card__info {
    width: 60%;
    padding: 0 18px;

    a {
      color: inherit;
    }

    a:hover {
      color: #32c2ef;
    }
  }

  .products-list__head:after {
    display: block;
    content: "";
    width: 36px;
    margin: 0 16px;
  }

  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .product-card__image {
      width: 60px !important;
      margin-right: 16px;
    }

    .product-card__name {
      padding: 0 18px;
    }
  }

  @media (min-width: 576px) {
    .product-card__prices {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      align-self: stretch;
      align-items: flex-end;
      width: 120px;
      font-size: 15px;
    }

    .product-card .product-card__prices {
      border-right: 1px solid #ebebeb;
      padding-right: 16px;
    }
  }

  .product-card__meta {
    border-left: 1px solid #ebebeb;
  }

  .product-card__badges {
    left: 0;
    margin-right: 2px;
  }

  .product-card__badges {
    position: unset !important;
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .product-card__badges>*+* {
    margin-top: unset !important;
  }

  .tag-badge:before {
    transform: none;
    border-radius: 1.5px;
  }

  .tag-badge {
    padding: 3px 5px;
    height: 15px;
    font-size: 10px;
  }

  .product-card__name {
    display: flex;
  }

  .product-card__meta {
    font-size: 13px;
  }

  .product-card__meta {
    font-size: 12px;
    line-height: 1;
    color: #999;
  }
}

.d-block {
  .product-card__prices {
    flex-grow: unset;
  }

  .products-list__item {
    min-height: max-content;
  }
}

@media (max-width: 620px) {
  .products-list-table .table {
    display: block;
  }

  .products-list__column--image {
    margin-left: 86.1px;
  }

  .products-list-table .products-list__column--price {
    margin-left: 70px;
  }

  .products-list__column--meta {
    margin-left: 86px;
  }

  .products-list-table .products-list__column--product {
    margin-left: 68px;
  }

  .products-list__column--price {
    margin-left: 70px;
  }

  .table {
    display: block;
  }

  .products-list-table .product-card {
    display: block;
  }

  .products-list-table .image__tag {
    width: 100px;
    margin-left: 50px;
  }

  .products-list-table .product-card__info {
    margin-left: 50px;
  }

  .products-list-table .product-card__price {
    margin-left: 70px;
  }

  .products-list-table .product-card .product-card__action {
    margin-left: 70px;
  }

  .products-list-table .product-card .product-card__addtocart-icon {
    margin-left: 70px;
  }

  .products-list-table .product-card {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}


.product-loader-div .card {
  width: 100%;
}