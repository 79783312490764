.all_menu {
  font-size: 14px;
}

.MuiDrawer-paper {
  .navbar-brand {
    text-align: center;
    margin-right: unset;
  }

  a {
    color: black;
    display: block;
    text-decoration: unset;
  }

  .megamenu-links__item-link a {
    padding-left: 8px;
  }

  .active {
    color: #32c2ef !important;
  }

  ul {
    list-style-type: none;
  }

  .sub-menu__link {
    color: #000;
  }

  .sub-menu-mobile {
    padding: 0 0 10px 5px;
    transition: width 2s;

    li {
      padding: 5px 10px !important;
      color: #000;
    }

    img {
      background-color: #32c2ef;
      padding: 2px;
    }

    .active {
      color: #fff;
    }

    li:hover {
      background-color: #32c2ef !important;
      color: #fff;

      a,
      .sub-menu__link {
        color: #fff;
      }
    }
  }

  .logo-image img {
    margin-top: 10px;
    height: 55px;
  }

  .search-input {
    margin: 10px 15px 0 15px;
  }

  .isSelected {
    color: #32c2ef !important;
    background-color: #f2f2f2;

    .sub-menu-mobile {
      li {
        color: black;
      }
    }
  }

  .main-menu-mobile {
    padding: unset;

    li {
      padding: 10px 25px;
      cursor: pointer;
      border: 1px solid #f3f3f3;
    }

  }

  .sub-menu-mobile li {
    border: unset;
  }

  .indicator__button {
    background: transparent;
    border: none;
  }
}