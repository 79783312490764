@import "../../variables.scss";

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.grid_nav {
  background-color: $primary-color;

}

//header style starts
.header_topbar_msg {
  background-color: #192e5b;
  text-align: center;
  color: $white;
  padding: 10px;
  min-height: 44px;

  a {
    color: $white;
  }
}

//header-style ends

//top-bar-style starts
.topbar_item {
  background-color: $light-gray;
  min-height: 44px;
  align-items: center;
  flex-wrap: wrap;
}


.topbar_item_label,
.topbar_item_value {
  font-size: 14px;
}

.topbar_item_label {
  color: $gray;
}

.topbar_item_value {
  color: $text-black;
}

a.topbar_item_value {
  text-decoration: underline;
}

//top-bar-style ends

.search-button {
  background-color: $text-black;
  color: $white;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 38px;
}

.search-input {
  margin-right: 50px;
  margin-top: 7px;
}

.navbar .megamenu {
  padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }

  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}

/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */


@media (max-width: 991px) {

  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}

@media (max-width: 1030px) {
  .search-input {
    margin-right: unset;
  }

  .logo-image img {
    height: 55px;
  }
}



//indicator css starts
@import "../../variables.scss";

.indicator {
  position: relative;

  .indicator__icon {
    position: absolute;
    padding: 8px;
    display: block;
    width: 48px;
    height: 48px;
    margin-top: 5px;
  }

  .indicator__button {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 54px;
    min-width: 65px;
    color: inherit;
    border-radius: 2px;
    background: transparent;
    border: none;
  }

  #wishlist-popover {
    margin-left: 15px;
  }

  .indicator__icon svg {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    transform: scaleX(1);
  }

  .indicator__counter {
    position: absolute;
    top: 5px;
    font-size: 10px;
    line-height: 1;
    padding: 2px 3px 1px;
    border-radius: 6.5px;
    text-align: center;
    z-index: 0;
    color: $white;
    font-weight: 500;
    right: 4px;
  }

  .indicator__counter:before {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 2.5px;
    background-color: $primary-color;
    left: 0;
    right: 0;
    transform: skewX(-11deg);
  }

  .indicator__title {
    padding-top: 3px;
    margin-bottom: -3px;
    font-size: 13px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #999;
    margin-left: 50px;

  }

  .indicator__value {
    margin-left: 50px;
    font-weight: 500;
    font-size: 18px;
  }
}

//indicator css ends

//dropcart css starts
.dropcart {
  display: block;
  width: 320px;
  background-color: #fff;
  border-radius: 1.5px;
  color: #262626;
  min-height: 100px;
  padding: 20px;
  box-shadow: 0px 2px 10px #0000001a;

  .dropcart__list {
    max-height: 300px;
    overflow-y: auto;
  }

  .dropcart__empty {
    text-align: center;
    font-size: 15px;
    padding: 20px 0;
  }

  .dropcart__divider {
    height: 1px;
    background: #ebebeb;
    margin: 8px 0;
  }

  .dropcart__item-meta {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    margin-top: 4px;
  }

  .dropcart__item-name {
    font-size: 15px;
    line-height: 18px;
  }

  .dropcart__item-info {
    padding: 4px 0 5px;
    flex-grow: 1;
    margin: 0 10px;
  }

  .dropcart__item-quantity {
    color: #fff;
    font-weight: 500;
  }

  .dropcart__item-price,
  .dropcart__item-quantity {
    position: relative;
    height: 16px;
    z-index: 0;
    font-size: 11px;
    padding: 1px 10.82352px 0;
  }

  .dropcart__item-name a {
    color: inherit;
    transition: color 0.2s;
  }

  .dropcart__item-quantity:before {
    left: 0;
    transform: skewX(-20deg);
    transform-origin: left bottom;
    background: #e52727;
  }

  .dropcart__item-price:before {
    background: #ebebeb;
  }

  .dropcart__item-quantity:after {
    background: #e52727;
  }

  .dropcart__item-price:before,
  .dropcart__item-quantity:before {
    left: 0;
    transform: skewX(-20deg);
    transform-origin: left bottom;
  }

  .dropcart__item-price:before,
  .dropcart__item-quantity:before {
    position: absolute;
    display: block;
    content: "";
    width: calc(100% - 5.82352px);
    height: 100%;
    top: 0;
    z-index: -1;
    border-radius: 2.5px 3px;
  }

  .dropcart__item-quantity:after {
    left: 0;
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
  }

  .dropcart__item-quantity:after {
    position: absolute;
    display: block;
    content: "";
    width: 8.32352px;
    height: 100%;
    top: 0;
    z-index: -1;
  }

  .dropcart__item-remove {
    position: relative;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    fill: #999;
    background-color: #fff;
    color: #ccc;
  }

  .dropcart__item-remove:hover {
    background-color: #f2f2f2;
    color: #999;
  }

  .dropcart__list {
    list-style: none;
    padding: 0;
    margin: -8px 0 0;
  }

  .dropcart__item {
    display: flex;
  }

  .dropcart__item-image {
    flex-shrink: 0;
    width: 70px;
  }

  .image--type--product img {
    object-fit: unset;
  }

  .image__body {
    display: block;
    position: relative;
    width: 100%;
  }

  .image--type--product {
    padding: 5px;
  }

  .dropcart__totals {
    font-size: 15px;
    margin-top: 18px;
    margin-bottom: 24px;
  }

  .dropcart__totals table {
    width: 100%;
  }

  .dropcart__totals th {
    font-weight: 500;
  }

  .dropcart__totals td,
  .dropcart__totals th {
    padding: 0;
  }

  .dropcart__totals td {
    text-align: right;
  }

  .dropcart__actions>* {
    flex-grow: 1;
  }

  .dropcart__actions {
    display: flex;
  }

  .dropcart__actions>*+* {
    margin-left: 8px;
  }
}


//dropcart css ends



.account-menu {
  display: block;
  width: 250px;
  background-color: #fff;
  border-radius: 1.5px;
  color: #262626;
  padding: 10px;
  box-shadow: 0px 2px 10px #0000001a;
}

.account-menu__form {
  padding: 0px 10px 20px;
}

.account-menu__form-title {
  text-align: center;
  padding: 15px 0 25px;
  font-weight: 500;
}

.account-menu__form-forgot {
  position: relative;
}


.account-menu__form-forgot-link {
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-radius: 2px;
  right: 5px;
  font-size: 12px;
  background: fff;
  color: #32c2ef !important;
  display: flex;
  align-items: center;
  padding: 0 7px;
  transition: background .1s, color .1s;
}

.account-menu__form-button {
  margin-top: 32px;
  text-align: center;

  button {
    width: max-content;
  }
}

.account-menu__form-link {
  font-size: 14px;
  text-align: center;
}


.account-menu__form-link a {
  color: #6c757d;
  transition: color .1s;
}


.account-menu__form-forgot-link:hover {
  background: #333;
  color: #fff !important;
}