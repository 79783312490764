.dashboard {
    text-align: center;
    padding: 20px;
    width: 300px;
    background-color: white;
    box-shadow: 0px 2px 10px #0000001a;

    // .account-dashboard-nav {
    text-align: unset;

    .profile-card__email {
        color: #6c757d;
        margin-bottom: unset;
        word-break: break-word;
    }

    .account-menu__divider {
        height: 1px;
        background: #ebebeb;
    }

    .account-menu__links {
        list-style: none;
        padding: 12px 0;
        margin: 0;
    }

    .account-nav__item a,
    .account-nav__item button {
        color: #262626;
        font-weight: 500;
    }

    .profile-card__avatar {
        margin: auto;
    }

    .account-nav__list {
        padding-bottom: unset;
    }

    .account-nav__list {
        margin: 0;
        padding: 0 0 1.375rem;
        font-size: 15px;
        line-height: 20px;
        list-style: none;
    }

    .account-nav__item a,
    .account-nav__item button {
        cursor: pointer;
        color: #6c757d;
        width: 100%;
        background: none;
        border: none;
        padding: 7px 1.5rem;
        display: block;
    }

    .account-nav__divider {
        height: 1px;
        background: #ebebeb;
        margin: 10px 0;
    }

    .profile-card__avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin-bottom: 16px !important;
        margin: auto;
        border-radius: 50%;
        background-color: #32c2ef;
        position: relative;
        color: #fff;
        font-weight: 500;
        font-size: 35px;
    }



    .drop-profile-card {
        width: 44px;
        height: 44px;
        margin: unset !important;
        margin-right: 10px !important;
        margin-bottom: 15px !important;
        background-color: #32c2ef;
        position: relative;
        color: #fff;
        font-weight: 500;
    }

    .profile-card__body {
        height: 250px;
    }

    .profile-card__avatar img {
        border-radius: 50%;
        max-width: 100%;
    }

    .profile-card__name {
        font-weight: 500;
        line-height: 20px;
    }

    .profile-card__email {
        font-size: 15px;
        margin-bottom: 24px;
    }

    .address-card {
        position: relative;
        height: 100%;
    }

    .address-card__badge {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    .tag-badge:before {
        transform: skewX(-20deg);
    }

    .tag-badge--theme:before {
        background: #32c2ef;
    }

    .tag-badge:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 2.5px;
        transform-origin: center;
        left: 3.5px;
        right: 3.5px;
    }

    .address-card__body {
        padding: 2rem;
        font-size: 15px;
        line-height: 18px;
        text-align: left;
    }

    .address-card__row+.address-card__row {
        margin-top: 0.75rem;
    }

    .address-card__row-title {
        font-size: 13px;
        color: #6c757d;
    }

    .address-card__name {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 1.125rem;
    }

    .dashboard__orders {
        margin-top: 24px;
        width: 100%;
    }
}



.dashboard-user {
    width: 100%;
}


@media (max-width: 768px) {
    .address-div {
        margin-top: 20px;
    }

}

@media (max-width: 575px) {

    .my-account-div {
        margin-top: 20px;
    }
}


.dashboard__orders {
    min-height: 350px;
    margin-top: 24px;
    width: 100%;

    .card-header:first-child {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
        text-align: left;
    }

    .card-header {
        border-radius: 0;
        background: transparent;
        padding: 1.25rem 2rem;
        border: none;
    }


    .card-table th:first-child {
        padding-left: 2rem;
    }

    .card-table thead th {
        border-bottom: 1px solid #ebebeb;
        text-transform: uppercase;
        font-size: 13px;
        padding: 10px 12px;
        background-color: #32c2ef;
        color: #fff;
    }



    .card-table table {
        text-align: left;
    }

    .card-table table {
        width: 100%;
    }

    table {
        border-collapse: collapse;
    }


    .card-table td:first-child,
    .card-table th:first-child {
        padding-left: 2rem;
    }

    .card-table tbody td,
    .card-table tbody th,
    .card-table tfoot td,
    .card-table tfoot th {
        padding: 12px;
    }

    .card-table tbody tr {
        border-bottom: thin solid #f1f1f1;

    }

    table {
        border-collapse: collapse;
    }

    @media (max-width: 767.98px) {
        .card-table {
            font-size: 14px;

            td,
            th {
                padding: 10px 5px;
            }
        }

        .cart-container {
            padding: 10px 7px !important;
        }

        .breadcrumb {

            margin-bottom: unset;
        }
    }
}

.wishlist__column:first-child {
    padding-left: 16px;
}

.wishlist__table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #ebebeb;

    .form-check-input {
        margin: unset;
        margin-left: -7px;
    }

    .td-input-check__box {
        padding-top: 8px;
        padding-left: 10px;
    }

}


.wishlist__column-checkbox {
    width: 40px;
}

.wishlist__column--image {
    text-align: center;
    width: 1px;
}

.wishlist__column--head {
    font-size: 13px;
    text-transform: uppercase;
}

.wishlist__column--button {
    text-align: right;
}

.wishlist__column--image img {
    width: 80px;
}

.wishlist__product-name {
    line-height: 20px;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wishlist__remove {
    svg {
        fill: #ccc;
    }
}

.btn-muted:hover {
    svg {
        fill: #222;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .wishlist__column:last-child,
    [dir="rtl"] .wishlist__column:first-child {
        padding-right: 16px;
    }
}

@media (min-width: 768px) {

    .wishlist__column:last-child,
    [dir="rtl"] .wishlist__column:first-child {
        padding-right: 28px;
    }
}

@media (min-width: 768px) {
    .wishlist__column--remove {
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .wishlist__column--remove {
        width: 1px;
        white-space: nowrap;
    }
}

.wishlist__column {
    padding: 14px 16px;
}


.btn-all-add-to-cart {
    width: max-content;
}



.wishlist__column--body {
    border-top: 1px solid #ebebeb;
}

.wishlist__product-name a {
    color: inherit;
    transition: color 0.15s;
}