.header__navbar {
  min-height: 52px;
  padding: 10px 0;
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
}

.departments__item {
  border-bottom: 1px solid #289cc1;
}

.departments__button,
.header__navbar {
  background-color: #32c2ef !important;
  color: #fff;
}

.sub-menu__link {
  color: #fff;
  font-size: 14px;
}

.departments__button:hover .departments__menu {
  display: block !important;
}

.main-menu__link_active {
  background-color: #2daed7 !important;
  padding: 5px;
  font-weight: 600;
}

.main-menu__link {
  color: #fff;
}


.header__navbar-departments {
  width: 330px;
}

.header__vertical_bar {
  background-color: #8ed6ed;
  height: 35px;
  width: 2px;
}

.header__navbar-menu {
  width: 550px;
}

.main-menu {
  height: 100%;
}

.main-menu__list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.main-menu__list {
  -moz-box-pack: space-evenly;
  justify-content: space-evenly;
}

.main-menu__item--hover {
  background-color: #2daed7;
}

.main-menu__item:hover,
.main-menu__link:hover {
  background-color: #2daed7 !important;
  color: #fff !important;
  text-decoration: none !important;
}

.departments {
  display: block;
  position: relative;
}

@media (max-width: 992px) {

  .main-menu__list {
    -moz-box-pack: space-between;
    justify-content: space-between;
  }

}

@media (max-width: 750px) {
  .main-menu__list {
    -moz-box-pack: space-around;
    justify-content: space-around;
  }

}

@media (min-width: 1200px) {
  .departments__button {
    padding-right: 0;
  }
}

.departments__button {
  padding-left: 35px;
  padding-right: 11px;
  text-align: left;
}

.departments__button {
  background: #fff;
  color: #262626;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .departments__button {
    height: 32px;
    display: block;
  }
}

@media (min-width: 576px) {
  .departments__button {
    font-size: 15px;
  }

  .main-menu__link {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .departments__button {
    font-size: 12px;
  }

  .main-menu__link {
    font-size: 12px;
  }
}

.departments__button {
  position: relative;
  fill: currentColor;
  border: none;
  border-radius: 2px;
  font-family: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background-color 0.2s, color 0.2s;
}

.departments__button {
  padding-left: 0px !important;
  width: 100%;
}

.departments__button,
.header__navbar {
  background-color: #32c2ef !important;
  color: #fff;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  border-radius: 0;
}

.departments__button .departments__button-icon {
  color: #404040;
}

.departments__button-icon {
  left: 10px;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
}

.departments__button .departments__button-icon,
.main-menu .main-menu__item--has-submenu .main-menu__link svg {
  color: #fff !important;
}

.departments__button-icon {
  top: 10px;
  position: absolute;
  fill: currentColor;
  display: inline-block;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
  margin-right: 20px;
}

.departments__button-icon {
  top: unset !important;
  position: unset !important;
}

.departments__button-icon svg {
  display: block;
}

@media (min-width: 1300px) {
  .grid_navBar {
    grid-template-columns: 80px 1fr auto 1fr 80px;

  }


}

@media (min-width: 1200px) {
  .departments__button-title {
    color: #fff;
  }
}

.departments__button .departments__button-arrow {
  color: #fff;
}

.departments__button-arrow {
  right: 14px;
}

.departments__button-arrow {
  display: none;
  fill: #8ed6ed;
  position: absolute;
  top: calc(50% - 4px);
  transition: transform 0.2s, color 0.2s;
}

.departments__menu {
  position: absolute;
  z-index: 1;
  top: 130%;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}

.departments__body {
  float: left;
  margin-top: 10px;
}

.departments__body {
  border-radius: 1.5px;
  pointer-events: auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 2px 10px #0000001a;
  color: #262626;
  width: 330px;
}

.departments__list {
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  background-color: #2daed7;
  font-weight: 500;
  width: 100%;
}

.departments__item-link {
  padding: 10px 25px;
  color: #fff;
}

.departments__item-link {
  display: block;
  position: relative;
}

@media (min-width: 576px) {
  .departments__item-arrow {
    right: 11px;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@media (max-width: 576px) {
  .departments__item-arrow {
    right: 11px;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.departments__item-arrow {
  fill: #fff;
  position: absolute;
  top: calc(50% - 10px);
}

.megamenu-area_start {
  grid-column: 1/2;
  background: #32c2ef;
  height: 100%;
  grid-row: 3;
}

.grid_navBar {
  display: grid;
  grid-template-columns: 40px 1fr auto 1fr 80px;
  background: #32c2ef;
}

.departments__megamenu {
  box-shadow: inset 1px 0 #ebebeb;
}

.megamenu {
  position: relative;
}

@media (min-width: 1200px) {
  .departments__megamenu--size--lg {
    width: 732px;
  }
}

@media (min-width: 1200px) {
  .departments__megamenu {
    padding: 17px 20px 20px;
  }
}

.departments__megamenu {
  min-height: 300px;
  // display: none;
  min-width: 700px;
  max-width: 800px;
}

@media (min-width: 1200px) {
  .departments__megamenu .row {
    margin: 0 -10px;
  }
}

@media (min-width: 1090px) {

  .departments__megamenu {
    width: 650px;
  }

}

.circular-progress {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.megamenu-links {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.megamenu-links__item {
  // line-height: 18px;
  background-color: #289cc1;
  margin-bottom: 10px;
  padding: 5px;
}

.megamenu-links {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

@media (min-width: 1200px) {
  .megamenu-links__item-link {
    font-size: 15px;
  }
}

.megamenu-links__item-link {
  font-size: 16px;
  color: inherit;
  -webkit-transition: color 0.15s;
  -moz-transition: color 0.15s;
  transition: color 0.15s;
  font-weight: 500;
}

img,
svg {
  vertical-align: middle;
}

img {
  border-style: none;
}

.megamenu__image {
  right: 0;
  -webkit-transform-origin: bottom right;
  -moz-transform-origin: bottom right;
  transform-origin: bottom right;
}

@media (min-width: 1200px) {
  .megamenu__image {
    -webkit-transform: scale(0.81);
    -moz-transform: scale(0.81);
    transform: scale(0.81);
  }
}

.megamenu__image {
  bottom: 0;
  float: right;
}

.megamenu__image img,
.megamenu__image picture {
  display: block;
  max-height: 300px;
  max-width: 300px;
  margin: 10px;
}

.reflect-rtl {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
}

.departments__menu-container>.departments__megamenu--size--lg {
  padding: 10px;
}

@media (min-width: 576px) {
  .departments__menu-container {
    position: absolute;
    left: 100%;
    top: 10px;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 10px #0000001a;
    background-color: #fff;
    border-radius: 1.5px;
  }
}

@media (max-width: 576px) {
  .departments__menu-container {
    display: flex;
    width: 100%;
    left: 100%;
    top: 10px;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 10px #0000001a;
    background-color: #fff;
    border-radius: 1.5px;
  }
}

.departments__item:hover {
  background: #289cc1;

  a {
    color: #262626;
  }

  .megamenu-links__item:hover {
    transform: scale(1.05);
    background: #32c2ef;

    a {
      text-decoration: none;
      color: white;
    }
  }
}

.main-menu__item {
  padding: 5px;
}

@media (max-width: 750px) {
  .megamenu-area_start {
    display: none;
  }

  .grid_navBar {
    display: contents;
  }
}

#simple-popover {
  z-index: 1400;
}