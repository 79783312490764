.product-card {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.products-list__item {
  min-height: 360px;
}


.product-grid,
.product-desc {
  .products-list__item:hover {
    transform: scale(1.02);
  }

}

.products-list__item .product-card {
  width: 100%;
  border-radius: 4px;
}

.product-card {
  box-shadow: 0px 2px 10px #0000001a;
}

.product-card__image {
  height: 220px;
}

.products-list .product-card .product-card__image {
  display: block;
  position: relative;
}

.product-card__name {
  padding: 16px;
  line-height: 1.1875;
}

.product-card__name {
  font-size: 16px;
}

.products-list .product-card .product-card__actions-list {
  right: 0;

}

.products-list .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
}

.products-list .product-card .product-card__badges {
  left: 16px;
}

.products-list .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}

.tag-badge {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  z-index: 0;
  height: 18px;
  padding: 4px 14px 0;
  color: #fff;
}

.tag-badge:before {
  transform: skewX(-20deg);
}

.tag-badge--sale:before {
  background: #f33;
}

.tag-badge:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
  transform-origin: center;
  left: 3.5px;
  right: 3.5px;
}

.tag-badge--new:before {
  background: #37f;
}

.tag-badge--hot:before {
  background: #614ba6;
}

.products-list__item {
  margin: 10px;
  flex-shrink: 0;
  display: flex;
}

.product-card__action--wishlist,
.product-card:hover .product-card__action--compare,
.product-card__action--quickview,
.product-card__addtocart-icon {
  svg {
    fill: #32c2ef;
  }
}

.product-card__addtocart-icon:hover {
  svg {
    fill: #fff;
  }
}

.product-card .product-card__action:hover {
  background-color: #000;
  color: #4d4d4d;

  svg {
    fill: #fff !important;
  }
}

.product-card__actions-list {
  position: absolute;
  overflow: hidden;
}

.products-list .product-card:active .product-card__action--compare,
.products-list .product-card:active .product-card__action--wishlist,
.products-list .product-card:hover .product-card__action--compare,
.products-list .product-card:hover .product-card__action--wishlist {
  transform: none;
  opacity: 1;
}

.product-card:hover .product-card__action--wishlist,
.product-card:hover .product-card__action--compare {
  opacity: 1;
}

.product-card:hover {
  .product-card__addtocart-icon {
    svg {
      fill: #fff;
    }
  }
}

.product-card__action--wishlist,
.product-card__action--compare {
  transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
  will-change: transform;
}



.product-card .product-card__action {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0;
  border: none;
  background-color: #fff;
  transition: color 0.08s, background 0.08s;
}

.product-card__actions-list {
  right: 0;
  z-index: 100;
}

.image--type--category .image__body,
.image--type--product .image__body {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.image--type--product {
  width: 100%;
  padding: 10px 35px 0 35px;

  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
}

.cart__table {
  .image--type--product {
    padding: unset;
  }
}

.product-card__footer {
  padding: 16px;
  -moz-box-align: center;
  align-items: center;
  display: flex;
  order: 2;
  position: relative;
}

.product-card__prices {
  -moz-box-flex: 1;
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.product-card .product-card__prices {
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1;
  color: #32c2ef;
}

.product-card__addtocart-icon {
  margin: -3px;
}

.product-card:active .product-card__addtocart-icon,
.product-card:hover .product-card__addtocart-icon {
  color: #fff;
}

.product-card .product-card__addtocart-icon {
  position: relative;
  border: none;
  padding: 8px;
  border-radius: 2px;
  background: transparent;
  color: #ccc;
  z-index: 0;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

.product-card .product-card__addtocart-icon:before {
  transform: scale(1);
  background: #32c2ef;
}

.product-card__addtocart-icon svg {
  fill: #fff;
}

.product-card:active .product-card__addtocart-icon:before,
.product-card:hover .product-card__addtocart-icon:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  background: #32c2ef;
}

.product-card__badges {
  left: 16px;
}

.product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  flex-direction: column;
  -moz-box-align: start;
  align-items: flex-start;
}

.product-card:active .product-card__addtocart-icon:hover:before,
.product-card:hover .product-card__addtocart-icon:hover:before {
  background: #000;
}

.product-card__addtocart-icon:before {
  top: -2px;
  left: -2px;
  display: block;
  content: "";
  position: absolute;
  width: 42px;
  height: 42px;
  background: transparent;
  z-index: -1;
  border-radius: 50%;
  transform: scale(0);
  transition: background 0.2s, -webkit-transform 0.2s;
}

.product-card__name {
  font-size: 16px;
  line-height: 20px;
  min-height: 100px;
  max-height: 150px;
}

.product-card__name a {
  color: inherit;
}

.product-card__desc {
  padding: 0 16px 0 16px;
  line-height: 1.1875;
  max-width: 254px;

  h6 {
    font-size: 15px;
  }

  p {
    font-size: 14px;
    color: #6c757d;
  }

  .sku {
    color: #999;
  }
}

.product-card-list.products-list__item {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  margin: 0;

  .product-card {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .product-card .product-card__actions-list {
    right: 0;
  }

  .product-card__image {
    padding: 16px 24px;
    width: 260px;
    min-height: 200px;
  }

  .product-card .product-card__info {
    padding-top: 20px;
    padding-bottom: 57px;
    padding: 20px;
    flex-grow: 1;
    max-width: 400px;
  }

  .product-card .product-card__meta {
    right: 20px;
  }

  .product-card__meta {
    position: absolute;
    bottom: 0;
    padding: 0;
    height: 38px;
    line-height: 36px;
    border-top: 1px solid #ebebeb;
    text-align: center;
    width: 180px;
    font-size: 12px;
    color: #999;
  }

  .product-card__name {
    font-size: 16px;
    font-weight: 500;
    padding: unset;
    padding-bottom: 10px;
  }

  .product-desc-label {
    font-size: 15px;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
  }

  .product-desc-value {
    font-size: 14px;
  }

  .product-card__footer {
    border-left: 1px solid #ebebeb;
    width: 220px;
    align-items: center;
    order: 2;
    flex-shrink: 0;
    flex-direction: column;
    padding: 0 22px 49px;
  }

  .product-card .product-card__prices {
    padding: 28px 0;
    font-size: 20px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-card__wishlist,
  .product-card__compare {
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px 5px;
  }

  .product-card .product-card__compare,
  .product-card .product-card__wishlist {
    position: relative;
    border: none;
    background: transparent;
    color: #262626;
    fill: #d9d9d9;
    border-radius: 2px;
    transition: background 0.15s, fill 0.15s;
  }

  .product-card__wishlist:hover,
  .product-card__compare:hover {
    background: #f5f5f5;
    fill: #bfbfbf;
  }
}

.product-caytegory-pagination {
  margin: 10px;
}

@media (min-width: 768px) {
  .product-card-list .product-card .product-card__footer {
    border-left: 1px solid #ebebeb;
  }
}

@media (max-width: 1270px) {
  .product-card-list.products-list__item .product-card .product-card__info {
    width: 180px;
  }
}

@media (min-width: 768px) {
  .product-card-list .product-card .product-card__footer {
    width: 180px;
    align-items: center;
    display: flex;
    order: 2;
    flex-shrink: 0;
    flex-direction: column;
    padding: 0 22px 49px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .product-card-list .product-card .product-card__image {
    width: 192px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.product-card-list {
  .product-card__desc {
    padding: unset;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .products-list-table .product-card .product-card__image {
    margin-right: 16px;
  }
}

@media (min-width: 576px) {
  .products-list-table .product-card .product-card__image {
    margin-right: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .products-list-table .product-card .product-card__image {
    width: 60px !important;
  }
}

@media (min-width: 576px) {
  .products-list-table .product-card .product-card__image {
    width: 90px;
    flex-shrink: 0;
  }


}

@media (min-width: 715px) {

  .product-card-list.products-list__item .product-card__image {
    height: 200px;
  }
}

.products-list-table {
  .image {
    width: unset !important;
    padding: 5px !important;
    height: unset !important;
  }

  .products-list__item {
    display: unset !important;
    flex-shrink: unset !important;
    min-height: unset !important;
    border-top: unset !important;
    margin: unset !important;
    width: 100% !important;
  }
}


@media (max-width: 767.98px) {
  // .image--type--product {
  //   padding: unset;
  // }

  .wishlist__column--image img {
    width: 50px !important;
  }

  .wishlist__table {
    font-size: 14px;
  }



  .wishlist__column {
    padding: 10px 7px !important;

  }
}


@media (max-width: 40px) {

  .wishlist__column--image img {
    width: 30px !important;
  }



}