.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.checkout {
  .MuiPaper-elevation1 {
    box-shadow: unset;
  }



  .MuiAccordionSummary-content {
    margin: 0;
  }

  .alert-secondary {
    background: unset;
    border: unset;
    box-shadow: 0px 2px 10px #0000001a;
  }

  .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #ebebeb;
    border-radius: 50%;
  }

  input:checked~.check:after {
    display: block;
  }

  .check:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .check:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkout__totals-subtotals {
    vertical-align: top !important;
  }

  input:checked~.check {
    background-color: #32c2ef;
  }

  .check:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.check:after {
    display: block;
  }
}

.checkout .card-title {
  color: #32c2ef;
}

.checkout__totals-subtotals th {
  font-weight: 500;
}

.checkout__totals-header {
  font-size: 13px;
  text-transform: uppercase;
}

.checkout__totals-header th {
  padding-bottom: 4px;
  border-bottom: 1px solid #ebebeb;
}

.checkout__totals-subtotals tr:last-child td,
.checkout__totals-subtotals tr:last-child th {
  padding-bottom: 16px;
}

.checkout__totals-products tr:last-child td {
  padding-bottom: 16px;
}

.checkout__totals-products td {
  padding: 4px 0;
}

.checkout__totals {
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  border-spacing: 0;
  margin-bottom: 20px;
}

.checkout__totals-footer {
  font-size: 24px;
}

.checkout__totals-subtotals tr:first-child th,
.checkout__totals-subtotals tr:first-child td {
  padding-top: 16px;
  border-top: 1px solid #ebebeb;
}

.checkout__totals-subtotals td,
.checkout__totals-subtotals th {
  padding: 4px 0;
}

.checkout__totals-products tr:first-child td {
  padding-top: 16px;
}

.checkout__totals-footer tr:first-child th,
.checkout__totals-footer tr:first-child td {
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.checkout__totals-footer th {
  font-weight: 500;
}

.checkout__totals th:last-child,
.checkout__totals td:last-child {
  padding-left: 20px;
  text-align: right;
}

.payment-methods__item {
  border-radius: 2px;
  padding-bottom: 2px;
  border: 1px solid #ebebeb;
  transition: background 0.12s, border-color 0.12s;
  margin-bottom: 10px;
}

.payment-methods__item-header {
  display: flex;
  align-items: center;
  line-height: 18px;
  margin: 0;
  padding: 10px 12px 8px;
  cursor: pointer;
}

.checkout__payment-methods {
  margin-bottom: 20px;
}

.payment-methods__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payment-methods__item {
  border-radius: 2px;
  padding-bottom: 2px;
  border: 1px solid #ebebeb;
  transition: background 0.12s, border-color 0.12s;
}

.payment-methods__item--active,
.payment-methods__item--active:hover {
  border-color: #32c2ef;
}

.payment-methods__item-radio {
  margin-right: 8px;
}

.input-radio__input:checked~.input-radio__circle {
  background: #32c2ef;
}

.input-radio__circle {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #afaeae;
  transition: background 0.2s, -webkit-box-shadow 0.2s;
}

.input-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.login-msg {
  font-size: 14px;
  color: #5c6873;
}

.input-radio__circle:after {
  display: block;
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
  transform: scale(0);
  transition: background 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s;

  visibility: hidden;
}

.input-radio__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

a {
  color: #32c2ef;
}

.input-radio__circle:after {
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-radio__check {
  background-color: #32c2ef;
}

.input-check__input {
  opacity: 1;
}

.checkout .check {
  border: 1px solid #8f8f8f;
}

@media (max-width: 400px) {
  .checkout .card {
    width: 300px;
  }
}

@media (max-width: 400px) {
  .payment-methods__item-container .card {
    width: 200px;
    margin-right: -50px !important;
  }



  .checkout__totals th:last-child,
  .checkout__totals td:last-child {
    position: relative;
    text-align: center;
  }
}

@media screen and (max-width: 575px) {

  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.shipping-details{
  color: #999;
  text-align: left !important;

}

.shipping-details-title{
  width: 150px;
}